import React from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonText
} from '@ionic/react';
import { close, person } from 'ionicons/icons';

const modalStyles = {
  modal: {
    '--height': '90%',
    '--border-radius': '20px 20px 0 0'
  },
  header: {
    '--background': 'transparent',
    '--border-style': 'none'
  },
  closeButton: {
    margin: '8px',
    borderRadius: '50%',
    backgroundColor: '#f7f7f7',
    width: '32px',
    height: '32px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    padding: '16px 0',
    textAlign: 'center'
  },
  list: {
    background: 'transparent',
    padding: '0 16px'
  },
  item: {
    '--background': 'transparent',
    '--border-color': '#e0e0e0',
    '--padding-start': '0',
    '--padding-end': '0',
    '--inner-padding-end': '0',
    marginBottom: '16px'
  },
  label: {
    fontSize: '14px',
    color: 'var(--ion-color-medium)',
    marginBottom: '4px'
  },
  value: {
    fontSize: '16px',
    color: 'var(--ion-text-color)',
    fontWeight: '500'
  },
  discrepancy: {
    fontSize: '14px',
    marginTop: '8px',
    padding: '8px 12px',
    borderRadius: '8px',
    backgroundColor: 'rgba(var(--ion-color-warning-rgb), 0.1)'
  }
};

const formatDate = (date) => {
  return new Date(date).toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
};

const getDiscrepancyExplanation = (note) => {
  if (!note.discrepancy || note.discrepancy.type === 'match') return null;

  const previousExpectedAmount = note.expectedBalance - (note.type === 'Cash In' ? note.value : 0);
  const actualAmount = note.value;
  const difference = Math.abs(note.discrepancy.amount);

  if (note.discrepancy.type === 'shortage') {
    return `The cash drawer should have contained $${note?.discrepancy?.previousExpectedAmount?.toFixed(2)} based on previous transactions, 
    but the actual count was $${actualAmount.toFixed(2)}. This indicates $${difference} is missing from the drawer.`;
  } else {
    return `The cash drawer should have contained $${note?.discrepancy?.previousExpectedAmount?.toFixed(2)} based on previous transactions, 
    but the actual count was $${actualAmount.toFixed(2)}. This indicates there is $${difference} extra in the drawer.`;
  }
};

const NoteDetailsModal = ({ isOpen, onClose, note }) => {
  if (!note) return null;

  const getTypeColor = (type) => {
    switch (type) {
      case 'Cash In':
        return 'var(--ion-color-success)';
      case 'Cash Out':
        return 'var(--ion-color-danger)';
      case 'Audit':
        return 'var(--ion-color-primary)';
      default:
        return 'var(--ion-color-primary)';
    }
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} style={modalStyles.modal}>
      <IonHeader style={modalStyles.header}>
        <IonToolbar style={modalStyles.header}>
          <IonButtons slot="start">
            <IonButton onClick={onClose} style={modalStyles.closeButton}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle style={modalStyles.title}>Entry Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList style={modalStyles.list}>
          <IonItem style={modalStyles.item} lines="none">
            <IonLabel>
              <div style={modalStyles.label}>Type</div>
              <div style={{ ...modalStyles.value, color: getTypeColor(note.type) }}>
                {note.type}
              </div>
            </IonLabel>
          </IonItem>

          <IonItem style={modalStyles.item} lines="none">
            <IonLabel>
              <div style={modalStyles.label}>Amount</div>
              <div style={{ ...modalStyles.value, color: getTypeColor(note.type) }}>
                {note.type === 'Cash Out' ? '-' : note.type === 'Cash In' ? '+' : ''}
                ${Math.abs(note.value).toFixed(2)}
              </div>
            </IonLabel>
          </IonItem>

          {note.expectedBalance !== null && (
            <IonItem style={modalStyles.item} lines="none">
              <IonLabel>
                <div style={modalStyles.label}>Cash Balance</div>
                <div style={modalStyles.value}>
                  ${note.expectedBalance.toFixed(2)}
                </div>
              </IonLabel>
            </IonItem>
          )}

          {note.discrepancy && note.discrepancy.type !== 'match' && (
            <>
              <IonItem style={{
                ...modalStyles.item,
                '--background': note.discrepancy.type === 'shortage' 
                  ? 'rgba(var(--ion-color-danger-rgb), 0.1)'
                  : 'rgba(var(--ion-color-warning-rgb), 0.1)',
                borderRadius: '12px',
                margin: '16px 0'
              }} lines="none">
                <IonLabel>
                  <div style={{
                    color: note.discrepancy.type === 'shortage' 
                      ? 'var(--ion-color-danger)'
                      : 'var(--ion-color-warning)',
                    fontWeight: '500',
                    padding:20,
                    paddingBottom:0
                  }}>
                    {note.discrepancy.type === 'shortage' ? 'Shortage' : 'Overage'}: ${note.discrepancy.amount}
                  </div>
                  <div style={{
                    fontSize: '14px',
                    marginTop: '8px',
                    color: 'var(--ion-color-medium)',
                    lineHeight: '1.4',
                    padding:20
                  }}>
                    {getDiscrepancyExplanation(note)}
                  </div>
                </IonLabel>
              </IonItem>
            </>
          )}

          {note.note && (
            <IonItem style={modalStyles.item} lines="none">
              <IonLabel>
                <div style={modalStyles.label}>Note</div>
                <div style={modalStyles.value}>{note.note}</div>
              </IonLabel>
            </IonItem>
          )}

          <IonItem style={modalStyles.item} lines="none">
            <IonLabel>
              <div style={modalStyles.label}>Date & Time</div>
              <div style={modalStyles.value}>{formatDate(note.date)}</div>
            </IonLabel>
          </IonItem>

          {note.user?.email && (
            <IonItem style={modalStyles.item} lines="none">
              <IonLabel>
                <div style={modalStyles.label}>{note.type === 'Audit' ? 'Audited By' : 'Created By'}</div>
                <div style={{ ...modalStyles.value, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <IonIcon icon={person} />
                  {note.user.email}
                </div>
              </IonLabel>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default NoteDetailsModal; 