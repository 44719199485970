// src/components/CalendarHeader.jsx
import React, { useState } from 'react';
import EmployeeHoursModal from './EmployeeHoursModal';

const CalendarHeader = ({ employees, appointments, selectedDate, refreshAppointments }) => {
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setShowHoursModal(true);
  };

  return (
    <>
      <div
        style={{
          gridColumn: '1 / 2',
          gridRow: '1 / 2',
          borderBottom: '1px solid #ddd',
          position: 'sticky',
          top: 0,
          left: 0,
          backgroundColor: '#fff',
          zIndex: 5000,
          padding: '8px',
          fontSize: '14px',
        }}
      >
        
      </div>
      {employees.map((employee, index) => (
        <div
          key={employee._id}
          style={{
            gridColumn: `${index + 2} / ${index + 3}`,
            gridRow: '1 / 2',
            padding: '8px',
            borderBottom: '1px solid #f0f0f0',
            borderLeft: '1px solid #f0f0f0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
            zIndex: 5000,
            transition: 'background-color 0.2s ease',
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
          onClick={() => handleEmployeeClick(employee)}
        >
          <span style={{ 
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '14px',
            fontWeight: '600',
            color: '#222',
            textAlign: 'center'
          }}>
            {employee.firstName} {employee.lastName}
          </span>
          <span style={{
            fontSize: '12px',
            color: '#666',
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
            <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <circle cx="12" cy="12" r="10"/>
              <polyline points="12 6 12 12 16 14"/>
            </svg>
          </span>
        </div>
      ))}

      {selectedEmployee && (
        <EmployeeHoursModal
          isOpen={showHoursModal}
          onClose={() => {
            setShowHoursModal(false);
            setSelectedEmployee(null);
          }}
          employee={selectedEmployee}
          date={selectedDate}
          refreshAppointments={refreshAppointments}
        />
      )}
    </>
  );
};

export default CalendarHeader;
