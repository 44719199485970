import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { AuthContext } from '../auth';

// Get WebSocket URL based on environment
const getWebSocketUrl = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'develop') {
        return `ws://${window.location.hostname}:9000`;
    } else {
        return `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/`;
    }
};

const SocketContext = createContext();

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { firebaseUser } = useContext(AuthContext);
    useEffect(() => {
        console.log("WebSocket URL:", getWebSocketUrl());
        console.log("WebSocket Path:", process.env.REACT_APP_ENVIRONMENT === 'develop' ? '/socket.io' : '/api/socket.io');
    }, []);

    useEffect(() => {
        console.log("=============Check Socket", firebaseUser?.uid)
        let socketInstance = null;

        if (firebaseUser?.uid) {
            // Initialize socket connection with Firebase UID
            socketInstance = io(getWebSocketUrl(), {
                auth: {
                    userId: firebaseUser.uid
                },
                transports: ['websocket'],
                //path: '/api/socket.io'
               path: process.env.REACT_APP_ENVIRONMENT === 'develop' ? '/socket.io' : '/api/socket.io' // Add this if your server uses a specific path
            });

            // Socket event listeners
            socketInstance.on('connect', () => {
                console.log('=============Socket connected with user:', firebaseUser.uid);
            });

            socketInstance.on('disconnect', () => {
                console.log('=============Socket disconnected');
            });

            socketInstance.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
            });

            setSocket(socketInstance);
        }

        // Cleanup on unmount or when firebaseUser changes
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
                setSocket(null);
            }
        };
    }, [firebaseUser?.uid]);

    const subscribeToCustomer = (customerId) => {
        if (socket && firebaseUser?.uid) {
            socket.emit('subscribe_customer', {
                customerId,
                firebaseId: firebaseUser.uid
            });

            // Listen for subscription responses
            socket.on('subscription_success', (data) => {
                console.log('=============Subscription success:', data);
            });

            socket.on('subscription_error', (error) => {
                console.error('=============Subscription error:', error);
            });
        }
    };

    const unsubscribeFromCustomer = (customerId) => {
        if (socket && firebaseUser?.uid) {
            socket.emit('unsubscribe_customer', {
                customerId,
                firebaseId: firebaseUser.uid
            });

            // Listen for unsubscription responses
            socket.on('unsubscription_success', (data) => {
                console.log('=============Unsubscription success:', data);
            });

            socket.on('unsubscription_error', (error) => {
                console.error('=============Unsubscription error:', error);
            });
        }
    };

    const value = {
        socket,
        subscribeToCustomer,
        unsubscribeFromCustomer,
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
}; 