import React, { useState } from 'react';
import {
    IonButton,
    IonLabel,
    IonToggle,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonIcon,
    IonAlert
} from '@ionic/react';
import { colors } from '../theme/colors';
import { fontWeights } from '../theme/typography';
import TimeSelector from '../commonComponents/timeSelector';
import { trashOutline } from 'ionicons/icons';
import axios from 'axios';

const ScheduleEditSection = ({
    employee,
    schedule,
    type, // 'schedule' or 'break'
    breakData, // only needed for break edits
    onSave,
    onCancel,
    fetchScheduleData,
}) => {
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [startTime, setStartTime] = useState(
        type === 'break' ? breakData?.startTime : schedule?.startTime || ''
    );
    const [endTime, setEndTime] = useState(
        type === 'break' ? breakData?.endTime : schedule?.endTime || ''
    );
    const [enabled, setEnabled] = useState(
        type === 'break' ? true : schedule?.enabled !== false
    );
    const [errorMessage, setErrorMessage] = useState('');

    const handleSave = async () => {
        setErrorMessage('');
        if (enabled && (!startTime || !endTime)) {
            setErrorMessage('**Start time and end time must be filled out');
            return;
        }

        onSave({
            startTime,
            endTime,
            enabled,
            ...(type === 'break' ? { breakId: breakData?._id } : {})
        });
    };

    const handleDeleteBreak = async () => {
        try {
            await axios.delete(`/employee/break?id=${breakData._id}`)
            fetchScheduleData()
            onCancel()
        } catch (error) {
            console.error('Error deleting break:', error)
            // alert('Failed to delete break. Please try again.')
        }
    }

    return (
        <IonGrid style={{ marginBottom: 80, flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
            {type === 'schedule' && (
                <IonRow style={{
                    padding: 10,
                    border: `1px solid ${colors.black30}`,
                    borderRadius: 10,
                    marginBottom: enabled ? 30 : 0
                }}>
                    <IonCol>
                        <IonRow>
                            <IonLabel style={{ fontWeight: fontWeights.weightBold }}>
                                Is {employee?.firstName} working this day?
                            </IonLabel>
                        </IonRow>
                        <IonRow style={{ marginTop: 10 }}>
                            <IonLabel style={{ color: colors.black40 }}>
                                Check the box to indicate yes
                            </IonLabel>
                        </IonRow>
                    </IonCol>
                    <IonCol size='auto' style={{ display: 'flex', alignItems: 'center' }}>
                        <IonToggle
                            mode='ios'
                            checked={enabled}
                            onIonChange={e => setEnabled(e.detail.checked)}
                        />
                    </IonCol>
                </IonRow>
            )}

            {(type === 'break' || enabled) && (
                <>
                    <IonRow
                        style={{
                            padding: 10,
                            border: `1px solid ${colors.black30}`,
                            borderBottom: 'none',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <IonLabel>Start Time</IonLabel>
                        <TimeSelector
                            dayKey="edit"
                            fieldKey="startTime"
                            value={startTime}
                            handleChange={(_, __, value) => setStartTime(value)}
                        />
                    </IonRow>
                    <IonRow
                        style={{
                            padding: 10,
                            border: `1px solid ${colors.black30}`,
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <IonLabel>End Time</IonLabel>
                        <TimeSelector
                            dayKey="edit"
                            fieldKey="endTime"
                            value={endTime}
                            minTime={startTime}
                            handleChange={(_, __, value) => setEndTime(value)}
                        />
                    </IonRow>

                    {type === 'break' && breakData?._id && (
                        <div
                            onClick={()=>{setShowDeleteAlert(true)}}
                            style={{
                                marginTop: '16px',
                                padding: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                                cursor: 'pointer',
                                color: '#ef4444',
                                transition: 'all 0.2s ease'
                            }}
                            onMouseEnter={e => {
                                e.currentTarget.style.backgroundColor = '#FEE2E2';
                                e.currentTarget.style.borderRadius = '8px';
                            }}
                            onMouseLeave={e => {
                                e.currentTarget.style.backgroundColor = 'transparent';
                            }}
                        >
                            <IonIcon icon={trashOutline} style={{ fontSize: '18px' }} />
                            <span style={{ fontSize: '14px' }}>Delete Break</span>
                        </div>
                    )}
                </>
            )}

            <IonText color='danger'>
                <p>{errorMessage}</p>
            </IonText>

            <IonAlert
                isOpen={showDeleteAlert}
                onDidDismiss={() => setShowDeleteAlert(false)}
                header="Delete Break"
                message="Are you sure you want to delete this break? This action cannot be undone."
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Delete',
                        role: 'destructive',
                        handler: handleDeleteBreak
                    }
                ]}
            />

            {/* Fixed Footer */}
            <div
                style={{
                    zIndex: 10,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    background: 'white',
                    padding: '10px 20px 40px 20px',
                    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <IonButton fill='clear' color={'dark'} onClick={onCancel}>
                    Cancel
                </IonButton>
                <IonButton
                    style={{
                        color: 'white',
                        fontWeight: '600',
                        justifyContent: 'center',
                        alignItems: 'end',
                        flexDirection: 'column',
                        display: 'flex',
                    }}
                    color={'dark'}
                    onClick={handleSave}
                >
                    <IonLabel style={{ padding: '0 22px' }}>Save</IonLabel>
                </IonButton>
            </div>
        </IonGrid>
    );
};

export default ScheduleEditSection; 