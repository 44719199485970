import React, { useState, useEffect } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonText,
  IonTextarea,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
  IonRadioGroup,
  IonRadio,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { close, checkmarkCircle, alertCircle, businessOutline, swapHorizontalOutline, ellipsisHorizontalOutline } from 'ionicons/icons';
import axios from 'axios';
import AddEntryModal from './AddEntryModal';

const modalStyles = {
  modal: {
    '--height': '90%',
    '--border-radius': '20px 20px 20px 20px'
  },
  header: {
    '--background': 'transparent',
    '--border-style': 'none'
  },
  closeButton: {
    margin: '8px',
    borderRadius: '50%',
    backgroundColor: '#f7f7f7',
    width: '32px',
    height: '32px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    padding: '16px 0',
    textAlign: 'center'
  },
  content: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  stepTitle: {
    fontSize: '28px',
    fontWeight: '600',
    color: '#222222',
    marginBottom: '0px',
    lineHeight: '1.0'
  },
  description: {
    fontSize: '16px',
    color: '#717171',
    marginTop: '-16px',
    marginBottom: '24px',
    lineHeight: '1.0'
  },
  input: {
    '--background': '#f7f7f7',
    '--padding-start': '16px',
    '--padding-end': '16px',
    '--padding-top': '16px',
    '--padding-bottom': '16px',
    '--border-radius': '12px',
    marginTop: '8px',
    marginBottom: '16px',
    fontSize: '32px',
    fontWeight: '600',
    '--text-align': 'center',
    textAlign: 'center'
  },
  textarea: {
    '--background': '#f7f7f7',
    '--padding-start': '16px',
    '--padding-end': '16px',
    '--padding-top': '12px',
    '--padding-bottom': '12px',
    '--border-radius': '12px',
    marginTop: '4px',
    marginBottom: '8px'
  },
  nextButton: {
    '--background': '#FF385C',
    '--background-hover': '#E31C5F',
    '--background-activated': '#E31C5F',
    '--border-radius': '8px',
    '--padding-top': '16px',
    '--padding-bottom': '16px',
    fontSize: '16px',
    fontWeight: '500',
    margin: '24px 0',
    textTransform: 'none'
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '24px 0',
    textAlign: 'center'
  },
  successIcon: {
    fontSize: '48px',
    color: 'var(--ion-color-success)'
  },
  warningIcon: {
    fontSize: '48px',
    color: '#FFB800'
  }
};

const reasonButtonStyle = (isSelected) => ({
  width: '100%',
  height: 'auto',
  padding: '16px',
  backgroundColor: isSelected ? '#F7F7F7' : 'white',
  border: `1px solid ${isSelected ? '#222222' : '#E0E0E0'}`,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  marginBottom: '8px'
});

const reasonIconStyle = {
  fontSize: '24px',
  color: '#222222'
};

const reasonTextStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px'
};

const reasonTitleStyle = {
  fontSize: '16px',
  fontWeight: '600',
  color: '#222222',
  margin: 0
};

const reasonDescriptionStyle = {
  fontSize: '14px',
  color: '#717171',
  margin: 0
};

const DailyCheckinModal = ({ isOpen, onClose, account, onSuccess, fetchData }) => {
  const [step, setStep] = useState(1);
  const [subStep, setSubStep] = useState(1);
  const [cashAmount, setCashAmount] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [expectedBalance, setExpectedBalance] = useState(0);
  const [auditSubmitted, setAuditSubmitted] = useState(false);
  const [cashOutAmount, setCashOutAmount] = useState('');
  const [cashOutReason, setCashOutReason] = useState('');
  const [otherAccounts, setOtherAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [otherReason, setOtherReason] = useState('');

  const calculateExpectedBalance = () => {
    console.log('Calculating balance with:', {
      notes: account?.notes,
      transactions: account?.transactions
    });

    if (!account?.notes?.length) {
      console.log('No notes found');
      return 0;
    }

    // Sort notes by date
    const sortedNotes = [...account.notes].sort((a, b) => new Date(a.date) - new Date(b.date));
    
    // Find the last audit
    const lastAudit = [...sortedNotes].reverse().find(note => note.type === 'Audit');
    if (!lastAudit) {
      console.log('No audit found');
      return 0;
    }

    console.log('Last audit:', lastAudit);

    // Get all transactions after the last audit
    const lastAuditDate = new Date(lastAudit.date);
    const notesAfterAudit = sortedNotes.filter(note => 
      new Date(note.date) > lastAuditDate
    );

    console.log('Notes after audit:', notesAfterAudit);

    // Calculate running balance starting from last audit
    let balance = lastAudit.value;
    console.log('Starting balance from audit:', balance);

    notesAfterAudit.forEach(note => {
      if (note.type === 'Cash In') {
        balance += note.value;
        console.log(`Added Cash In: ${note.value}, new balance: ${balance}`);
      } else if (note.type === 'Cash Out') {
        balance -= Math.abs(note.value);
        console.log(`Subtracted Cash Out: ${note.value}, new balance: ${balance}`);
      }
    });

    // Add any cash transactions after last audit
    if (account.transactions) {
      const transactionsAfterAudit = account.transactions.filter(
        t => new Date(t.date) > lastAuditDate && !t.customer?.subscription
      );

      console.log('Transactions after audit:', transactionsAfterAudit);

      transactionsAfterAudit.forEach(transaction => {
        const cashAmount = transaction.payment?.totalPrice - (transaction.payment?.cashTipAmount || 0);
        balance += cashAmount;
        console.log(`Added transaction: ${cashAmount}, new balance: ${balance}`);
      });
    }

    console.log('Final calculated balance:', balance);
    return balance;
  };

  useEffect(() => {
    if (isOpen) {
      setStep(1);
      setSubStep(1);
      setCashAmount('');
      setNote('');
      setAuditSubmitted(false);
      setExpectedBalance(calculateExpectedBalance());
      setCashOutAmount('');
      setCashOutReason('');
      setSelectedAccount(null);
      setOtherReason('');
      
      // Fetch other accounts
      fetchOtherAccounts();
    }
  }, [isOpen]);

  const fetchOtherAccounts = async () => {
    try {
      const response = await axios.get('/cashManagement/accounts');
      setOtherAccounts(response.data.filter(acc => acc._id !== account._id));
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    }
  };

  const handleSubmitAudit = async () => {
    setLoading(true);
    try {
      await axios.post('/cashManagement', {
        type: 'Audit',
        value: parseFloat(cashAmount),
        note: note,
        date: new Date(),
        accountId: account?._id
      });
      setAuditSubmitted(true);
      fetchData?.(false);
      setStep(2);
      setSubStep(1); // Reset subStep to show initial question
    } catch (error) {
      console.error('Failed to submit audit:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCashAmountChange = (value) => {
    setCashAmount(value);
    if (subStep > 1 && !isNaN(parseFloat(value))) {
      setSubStep(2);
    }
  };

  const handleNextClick = () => {
    const amountEntered = parseFloat(cashAmount);
    const isDifferent = amountEntered !== expectedBalance;

    if (subStep === 1) {
      setSubStep(2);
      if (!isDifferent) {
        handleSubmitAudit();
      }
    } else if (subStep === 2) {
      handleSubmitAudit();
    }
  };

  const handleTryAgain = () => {
    setSubStep(1);
    setCashAmount('');
  };

  const handleCashOut = async () => {
    setLoading(true);
    try {
      // Create Cash Out entry
      const cashOutNote = cashOutReason === 'bank' ? 'To the Bank' :
                         cashOutReason === 'move' ? `Moved to ${selectedAccount.name}` :
                         otherReason;

      await axios.post('/cashManagement', {
        type: 'Cash Out',
        value: parseFloat(cashOutAmount),
        note: cashOutNote,
        date: new Date(),
        accountId: account?._id
      });

      // If moving to another account, create Cash In entry
      if (cashOutReason === 'move' && selectedAccount) {
        await axios.post('/cashManagement', {
          type: 'Cash In',
          value: parseFloat(cashOutAmount),
          note: `Moved from ${account.name}`,
          date: new Date(),
          accountId: selectedAccount._id
        });
      }

      fetchData?.(false);
      onClose();
    } catch (error) {
      console.error('Failed to process cash out:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => {
    const amountEntered = parseFloat(cashAmount);
    const difference = amountEntered - expectedBalance;
    const isDifferent = amountEntered !== expectedBalance;

    return (
      <div style={modalStyles.content}>
        <h2 style={modalStyles.stepTitle}>
          How much cash is in {account?.name}?
        </h2>
        <p style={modalStyles.description}>
          Please count all cash and enter the total amount below
        </p>
        
        <IonItem lines="none" style={{ '--background': 'transparent', '--inner-padding-end': '0px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '80%', maxWidth: '300px' }}>
              <IonInput
                type="number"
                value={cashAmount}
                onIonInput={e => handleCashAmountChange(e.detail.value)}
                placeholder="0.00"
                style={modalStyles.input}
              />
            </div>
          </div>
        </IonItem>

        {subStep > 1 && !isNaN(amountEntered) && (
          <div style={modalStyles.resultContainer}>
            {!isDifferent ? (
              <>
                <IonIcon icon={checkmarkCircle} style={modalStyles.successIcon} />
                <IonText color="success">
                  <h3 style={{ fontWeight: '600', fontSize: '20px' }}>Perfect match!</h3>
                </IonText>
              </>
            ) : (
              <>
                <IonIcon icon={alertCircle} style={modalStyles.warningIcon} />
                <IonText color="warning">
                  <h3 style={{ fontWeight: '600', fontSize: '20px', margin: 0 }}>The amounts don't match</h3>
                </IonText>
                <IonText>
                  <p style={{ color: '#717171', marginBottom: '8px' }}>
                    There should be ${expectedBalance.toFixed(2)} in the drawer
                  </p>
                  <h3 style={{ fontWeight: '600', fontSize: '24px', color: '#222', margin: 0 }}>
                    You are {difference > 0 ? 'over by' : 'short by'} ${Math.abs(difference).toFixed(2)}
                  </h3>
                </IonText>
              </>
            )}
          </div>
        )}

        {subStep === 2 && isDifferent && (
          <IonTextarea
            placeholder="Add a note explaining the difference..."
            value={note}
            onIonInput={e => setNote(e.detail.value)}
            style={modalStyles.textarea}
            rows={4}
          />
        )}

        <div style={{ 
          display: 'flex', 
          gap: '12px',
          flexDirection: 'column'
        }}>
          <IonButton
            expand="block"
            style={modalStyles.nextButton}
            onClick={handleNextClick}
            disabled={loading || 
              cashAmount === '' || 
              isNaN(amountEntered)}
          >
            {loading ? <IonSpinner name="crescent" /> : 
             (subStep === 2 && isDifferent) ? 'Submit' : 'Next'}
          </IonButton>
        </div>
      </div>
    );
  };

  const renderStep2 = () => {
    if (subStep === 1) {
      return (
        <div style={modalStyles.content}>
          <h2 style={modalStyles.stepTitle}>
            Are you pulling any cash out today?
          </h2>
          <p style={modalStyles.description}>
            If you need to remove cash from the drawer, you can do that now
          </p>

          <div style={{ marginBottom: '24px' }}>
            <button
              style={reasonButtonStyle(false)}
              onClick={() => setSubStep(2)}
            >
              <IonIcon icon={swapHorizontalOutline} style={reasonIconStyle} />
              <div style={reasonTextStyle}>
                <p style={reasonTitleStyle}>Yes, remove cash</p>
                <p style={reasonDescriptionStyle}>Take cash out of the drawer</p>
              </div>
            </button>

            <button
              style={reasonButtonStyle(false)}
              onClick={onClose}
            >
              <IonIcon icon={checkmarkCircle} style={reasonIconStyle} />
              <div style={reasonTextStyle}>
                <p style={reasonTitleStyle}>No, I'm done</p>
                <p style={reasonDescriptionStyle}>Complete the daily check-in</p>
              </div>
            </button>
          </div>
        </div>
      );
    }

    return (
      <div style={modalStyles.content}>
        <h2 style={modalStyles.stepTitle}>
          How much cash are you pulling out of {account?.name}?
        </h2>
        
        <IonItem lines="none" style={{ '--background': 'transparent', '--inner-padding-end': '0px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '80%', maxWidth: '300px' }}>
              <IonInput
                type="number"
                value={cashOutAmount}
                onIonInput={e => setCashOutAmount(e.detail.value)}
                placeholder="0.00"
                style={modalStyles.input}
              />
            </div>
          </div>
        </IonItem>

        <h3 style={{ 
          fontSize: '18px', 
          fontWeight: '600', 
          color: '#222222',
          marginBottom: '16px'
        }}>
          What are you doing with it?
        </h3>

        <div style={{ marginBottom: '24px' }}>
          <button
            style={reasonButtonStyle(cashOutReason === 'bank')}
            onClick={() => {
              setCashOutReason('bank');
              setSelectedAccount(null);
            }}
          >
            <IonIcon icon={businessOutline} style={reasonIconStyle} />
            <div style={reasonTextStyle}>
              <p style={reasonTitleStyle}>To the Bank</p>
              <p style={reasonDescriptionStyle}>Deposit cash at the bank</p>
            </div>
          </button>

          {otherAccounts.map(acc => (
            <button
              key={acc._id}
              style={reasonButtonStyle(cashOutReason === 'move' && selectedAccount?._id === acc._id)}
              onClick={() => {
                setCashOutReason('move');
                setSelectedAccount(acc);
              }}
            >
              <IonIcon icon={swapHorizontalOutline} style={reasonIconStyle} />
              <div style={reasonTextStyle}>
                <p style={reasonTitleStyle}>Move to {acc.name}</p>
                <p style={reasonDescriptionStyle}>Transfer cash to {acc.name} drawer</p>
              </div>
            </button>
          ))}

          <button
            style={reasonButtonStyle(cashOutReason === 'other')}
            onClick={() => {
              setCashOutReason('other');
              setSelectedAccount(null);
            }}
          >
            <IonIcon icon={ellipsisHorizontalOutline} style={reasonIconStyle} />
            <div style={reasonTextStyle}>
              <p style={reasonTitleStyle}>Other</p>
              <p style={reasonDescriptionStyle}>Specify another reason</p>
            </div>
          </button>
        </div>

        {cashOutReason === 'other' && (
          <IonTextarea
            placeholder="Enter reason..."
            value={otherReason}
            onIonInput={e => setOtherReason(e.detail.value)}
            style={modalStyles.textarea}
            rows={3}
          />
        )}

        <div style={{ 
          display: 'flex', 
          gap: '12px',
          flexDirection: 'column',
          marginTop: '24px'
        }}>
          <IonButton
            expand="block"
            style={modalStyles.nextButton}
            onClick={handleCashOut}
            disabled={loading || 
              !cashOutAmount || 
              isNaN(parseFloat(cashOutAmount)) ||
              (cashOutReason === 'move' && !selectedAccount) ||
              (cashOutReason === 'other' && !otherReason.trim()) ||
              !cashOutReason}
          >
            {loading ? <IonSpinner name="crescent" /> : 'Submit'}
          </IonButton>
        </div>
      </div>
    );
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} style={modalStyles.modal}>
      <IonHeader style={modalStyles.header}>
        <IonToolbar style={modalStyles.header}>
          <IonButtons slot="start">
            <IonButton
              onClick={onClose}
              style={modalStyles.closeButton}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle style={modalStyles.title}>Daily Checkin</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {step === 1 && renderStep1()}
        {step === 2 && renderStep2()}
      </IonContent>
    </IonModal>
  );
};

export default DailyCheckinModal; 