import React from 'react'
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonText,
} from '@ionic/react'
import { format } from 'date-fns'
import { colors } from '../theme/colors'
import Avatar from '../commonComponents/avatar'

const ShimmerCell = () => {
  return (
    <div style={{
      padding: '6px',
      borderRadius: '6px',
      backgroundColor: '#F1F8FF',
      height: '52px',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(90deg, #F1F8FF 25%, #E3F2FF 50%, #F1F8FF 75%)',
        backgroundSize: '200% 100%',
        animation: 'shimmer 1.5s infinite linear'
      }} />
      <style>
        {`
          @keyframes shimmer {
            0% {
              background-position: 200% 0;
            }
            100% {
              background-position: -200% 0;
            }
          }
        `}
      </style>
    </div>
  );
};

const MobileScheduleView = ({ 
  employees, 
  selectedDate, 
  getScheduleForDay, 
  getBreaksForDay, 
  handleScheduleClick, 
  handleBreakClick,
  businessId,
  loading
}) => {
  return (
    <IonList>
      <IonListHeader>
        <IonLabel>
          <h1 style={{ margin: 0, fontSize: '1.2rem' }}>{format(selectedDate, 'EEEE')}</h1>
          <IonText color="medium" style={{ fontSize: '0.9rem' }}>{format(selectedDate, 'MMMM d, yyyy')}</IonText>
        </IonLabel>
      </IonListHeader>

      {employees.map(employee => {
        const schedule = !loading ? getScheduleForDay(employee, selectedDate) : null;
        const breaks = !loading ? getBreaksForDay(employee, selectedDate) : [];
        
        return (
          <IonCard key={employee._id} style={{ 
            margin: '8px',
            borderRadius: '8px',
            backgroundColor: 'white',
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
          }}>
            <IonCardContent style={{ padding: '12px 12px 8px 12px' }}>
              <div style={{ 
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
                gap: '8px'
              }}>
                <div style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '16px',
                  overflow: 'hidden',
                  flexShrink: 0,
                  marginTop: '4px'
                }}>
                  <Avatar employee={employee} businessId={businessId} mini />
                </div>
                <div style={{ 
                  fontWeight: 'bold',
                  fontSize: '0.95rem',
                  color: colors.black90
                }}>
                  {employee.firstName} {employee.lastName}
                </div>
              </div>

              {loading ? (
                <ShimmerCell />
              ) : schedule?.enabled === false ? (
                <div 
                  onClick={() => handleScheduleClick(employee, selectedDate, { schedule })}
                  style={{ 
                    color: colors.black60,
                    padding: '8px',
                    fontSize: '0.8rem',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    transition: 'all 0.2s ease'
                  }}
                  onMouseEnter={e => {
                    e.currentTarget.style.fontWeight = '600';
                    e.currentTarget.style.backgroundColor = colors.black05;
                  }}
                  onMouseLeave={e => {
                    e.currentTarget.style.fontWeight = 'normal';
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }}
                >
                  Day off
                </div>
              ) : !schedule ? (
                <div 
                  onClick={() => handleScheduleClick(employee, selectedDate)}
                  style={{ 
                    padding: '4px',
                    borderRadius: '4px',
                    border: `1px dashed ${colors.black20}`,
                    color: colors.black60,
                    cursor: 'pointer',
                    textAlign: 'center',
                    fontSize: '0.8rem'
                  }}
                >
                  Add hours
                </div>
              ) : (
                <>
                  <div 
                    onClick={() => handleScheduleClick(employee, selectedDate, { schedule })}
                    style={{
                      padding: '4px 6px',
                      borderRadius: '4px',
                      border: `1px solid ${colors.black10}`,
                      marginBottom: breaks.length > 0 ? '6px' : 0,
                      cursor: 'pointer',
                      backgroundColor: '#F1F8FF',
                      transition: 'all 0.2s ease'
                    }}
                    onMouseEnter={e => {
                      e.currentTarget.style.transform = 'scale(1.02)';
                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.transform = 'scale(1)';
                      e.currentTarget.style.boxShadow = 'none';
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1px'
                    }}>
                      <span style={{
                        fontSize: '0.65rem',
                        color: '#1565C0',
                        fontWeight: '600'
                      }}>
                        Working Hours
                      </span>
                      <div style={{
                        display: 'flex'
                      }}>
                        <span style={{ 
                          fontSize: '0.8rem',
                          color: '#1976D2'
                        }}>
                          {schedule.startTime} - {schedule.endTime}
                        </span>
                      </div>
                    </div>
                  </div>

                  {breaks.map(breakItem => (
                    <div
                      key={breakItem._id}
                      onClick={() => handleBreakClick(employee, selectedDate, breakItem)}
                      style={{
                        padding: '4px 6px',
                        borderRadius: '4px',
                        backgroundColor: '#F3E5F5',
                        marginTop: '4px',
                        border: `1px solid ${colors.black10}`,
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1px',
                        transition: 'all 0.2s ease'
                      }}
                      onMouseEnter={e => {
                        e.currentTarget.style.transform = 'scale(1.02)';
                        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.boxShadow = 'none';
                      }}
                    >
                      <span style={{
                        fontSize: '0.65rem',
                        color: '#6A1B9A',
                        fontWeight: '600'
                      }}>
                        Break
                      </span>
                      <span style={{
                        fontSize: '0.8rem',
                        color: '#6A1B9A'
                      }}>
                        {breakItem.startTime} - {breakItem.endTime}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </IonCardContent>
          </IonCard>
        )
      })}
    </IonList>
  )
}

export default MobileScheduleView 