import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { formatDateToISOString, getFormattedMediumDate } from '../../utils'
import TimeSelector from '../../commonComponents/timeSelector'
import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToggle,
} from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
import { colors } from '../../theme/colors'
import { checkmarkOutline, closeOutline } from 'ionicons/icons'
import { fontSizes, fontWeights } from '../../theme/typography'

function getDefaultData() {
  return {
    dateText: getFormattedMediumDate(new Date()),
    dateEpoch: new Date().getTime(),
    enabled: false,
    startTime: null,
    endTime: null,
  }
}

export default function AddBreak({ employee, onClose, open, handleSave, standardSchedule }) {
  const [data, setData] = useState(getDefaultData())
  const [errorMessage, setErrorMessage] = useState('error')
  const [isSaving, setIsSaving] = useState(false)
  const [repeatBreak, setRepeatBreak] = useState(false)
  const [breakType, setBreakType] = useState('daily')
  const [breakInterval, setBreakInterval] = useState(1)
  useEffect(() => {
    setData(getDefaultData())
    setIsSaving(false)
    setErrorMessage('')
    setRepeatBreak(false)
    setBreakType('daily')
    setBreakInterval(1)
  }, [open])
  const toggleRepeat = (value) => {
    setRepeatBreak(value)
  }
  const handleBreakType = (value) => {
    setBreakType(value)
  }
  const getDateBasedDay = (dateStr) => {
    const date = new Date(dateStr)
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dayIndex = date.getDay()
    const day = days[dayIndex]
    return day
  }

  const save = () => {
    setErrorMessage('')

    if (!data.startTime || !data.endTime) {
      setErrorMessage('**Start time and End time must be filled out')
      return
    }
    let newData = { ...data }
    newData.repeat = {
      enabled: repeatBreak,
      type: breakType, //"daily"
      interval: 1,
      occurrences: parseFloat(breakInterval),
    }
    if (breakType == 'weekly') {
      newData.repeat.day = getDateBasedDay(data.dateText)
    }
    setIsSaving(true)
    handleSave(newData)
  }

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value, d) => {
    onClose(value)
  }

  function handleChange(id, fieldKey, newValue) {
    console.log(id, fieldKey, newValue)
    if (fieldKey === 'endTime' && data.startTime) {
      // Convert times to comparable values (minutes since midnight)
      const startMinutes = convertTimeToMinutes(data.startTime);
      const endMinutes = convertTimeToMinutes(newValue);
      
      if (endMinutes <= startMinutes) {
        setErrorMessage('End time must be after start time');
        return;
      }
    }
    
    setErrorMessage('');
    setData({ ...data, ...{ [fieldKey]: newValue } })
  }

  function convertTimeToMinutes(time) {
    if (!time) return 0;
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  const changeInterval = (value) => {
    setBreakInterval(value)
  }
  function updateDate(id, date) {
    setData({
      ...data,
      ...{ dateText: getFormattedMediumDate(date), date: date, dateEpoch: date.getTime() },
    })
  }
  const isSaveDisabled = isSaving || !data.startTime || !data.endTime || (repeatBreak ? !breakType || !breakInterval : false)
  const commonRepeatBlockStyles = {
    marginTop: 20,
    padding: 10,
    border: `1px solid ${colors.black30}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '10px', // Always 10px
  }

  const conditionalRepeatBlockStyles = repeatBreak
    ? {
        borderBottom: 'none',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
      }
    : {
        borderBottom: `1px solid ${colors.black30}`,
      }

  console.log(data)

  return (
    <>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <IonContent>
          <StandardContainer padding={20}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h2>
                    Add a Break for {employee?.firstName} {employee?.lastName}
                  </h2>
                </IonCol>
                <IonCol size='1'>
                  <IonIcon
                    onClick={handleClose}
                    style={{ fontSize: fontSizes.size24, color: colors.black40, fontWeight: fontWeights.weightBold }}
                    icon={closeOutline}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid style={{ marginBottom: 80, flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              <IonRow style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <CalendarSelect dateText={data.dateText} id={data._id} updateDate={updateDate} />
              </IonRow>
              <>
                <IonRow
                  style={{
                    padding: 10,
                    border: `1px solid ${colors.black30}`,
                    borderBottom: 'none',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,

                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel>Start Time</IonLabel>
                  <TimeSelector dayKey={data._id} fieldKey='startTime' value={data?.startTime} handleChange={handleChange} />
                </IonRow>
                <IonRow
                  style={{
                    padding: 10,
                    border: `1px solid ${colors.black30}`,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <IonLabel>End Time</IonLabel>
                  <TimeSelector 
                    dayKey={data._id} 
                    fieldKey='endTime' 
                    value={data?.endTime} 
                    handleChange={handleChange}
                    minTime={data?.startTime}
                  />
                </IonRow>

                <IonRow style={{ ...commonRepeatBlockStyles, ...conditionalRepeatBlockStyles }}>
                  <IonCol>
                    <IonRow>
                      <IonLabel>Repeat</IonLabel>
                    </IonRow>
                  </IonCol>
                  <IonCol size='auto' style={{ display: 'flex', alignItems: 'center' }}>
                    <IonToggle
                      mode='ios'
                      checked={repeatBreak}
                      onIonChange={(e) => {
                        toggleRepeat(e.detail.checked)
                      }}
                    />
                    {repeatBreak && (
                      <IonIcon
                        onClick={() => toggleRepeat(false)}
                        icon={checkmarkOutline} // Or any other suitable icon from react-icons or IonIcons
                        style={{ cursor: 'pointer', position: 'absolute', right: 11, zIndex: 10, marginLeft: '8px', color: colors.primaryBlue }} // Adjust styling as needed
                      />
                    )}
                  </IonCol>
                </IonRow>
                {repeatBreak && (
                  <>
                    <IonRow
                      style={{
                        padding: 10,
                        borderLeft: `1px solid ${colors.black30}`,
                        borderTop: `1px solid ${colors.black30}`,
                        borderRight: `1px solid ${colors.black30}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <IonCol>
                        <IonLabel>Every</IonLabel>
                      </IonCol>
                      <IonCol style={{ marginLeft: '1rem', display: 'flex', justifyContent: 'end' }}>
                        <IonSelect
                          style={{ width: 'fit-content' }}
                          value={breakType}
                          onIonChange={(e) => handleBreakType(e.detail.value)}
                          placeholder='Select Repeat Type'
                        >
                          <IonSelectOption value='daily'>Daily</IonSelectOption>
                          <IonSelectOption value='weekly'>Weekly</IonSelectOption>
                        </IonSelect>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{
                        padding: 10,
                        border: `1px solid ${colors.black30}`,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {}
                      <IonCol>
                        <IonLabel># of times</IonLabel>
                      </IonCol>
                      <IonCol style={{ display: 'flex', justifyContent: 'end' }}>
                        <IonSelect
                          style={{ width: 'fit-content' }}
                          value={breakInterval}
                          onIonChange={(e) => changeInterval(e.detail.value)}
                          placeholder='Select Interval'
                        >
                          {[...Array(30).keys()].map((num) => (
                            <IonSelectOption key={num + 1} value={num + 1}>
                              {num + 1}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonCol>
                    </IonRow>
                  </>
                )}
              </>
            </IonGrid>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <IonText color='danger'>
              <p>{errorMessage}</p>
            </IonText>
            {/* Fixed Footer */}
            <div
              style={{
                zIndex: 10,
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                background: 'white',
                padding: '10px 20px 40px 20px',
                boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IonButton fill='clear' color={'dark'} onClick={handleClose}>
                Cancel
              </IonButton>
              <IonButton
                style={{
                  color: 'white',
                  fontWeight: '600',
                  justifyContent: 'center',
                  alignItems: 'end',
                  flexDirection: 'column',
                  display: 'flex',
                }}
                color={'dark'}
                disabled={isSaveDisabled}
                onClick={save}
              >
                <IonLabel style={{ padding: '0 22px' }}>Save</IonLabel>
              </IonButton>
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}
function CalendarSelect({ dateText, updateDate, id }) {
  const [value, setValue] = React.useState(dayjs(dateText))

  const handleChange = (newValue) => {
    updateDate(id, new Date(newValue))
    setValue(new Date(newValue))
  }

  return (
    <>
      <IonDatetime
        style={{ background: 'white', minWidth: '100%' }}
        id='datetime'
        presentation='date'
        value={formatDateToISOString(new Date(value))}
        onIonChange={(e) => {
          handleChange(e.detail.value)
        }}
        formatOptions={{
          date: {
            month: 'long',
          },
        }}
      ></IonDatetime>
    </>
  )
}
