import React from 'react';
import { IonList, IonItem, IonCheckbox, IonLabel, IonIcon } from '@ionic/react';
import Avatar from '../../commonComponents/avatar';
import { filterStyles } from './FilterStyles';
import { checkmarkOutline } from 'ionicons/icons';
import { colors } from '../../theme/colors';
// import { colors } from '../../constants/colors';

const StylistsFilter = ({ filteredBarbers, selectedStylists, handleStylistChange, businessId }) => {
    return (
        <div style={filterStyles.container}>
            <h6 style={filterStyles.header}>
                Stylists
            </h6>
            <IonList mode='ios' lines='none' style={filterStyles.list}>
                {filteredBarbers?.map((item, index) => (
                    <IonItem key={index} style={filterStyles.item} onClick={() => handleStylistChange(item.title)}>
                        <div style={filterStyles.avatarContainer}>
                            <Avatar employee={item} businessId={businessId} small />
                            <IonLabel style={filterStyles.label}>{item.title}</IonLabel>
                        </div>
                        {selectedStylists.includes(item.title) ? (
                            <div
                                slot='end'
                                style={{
                                    borderRadius: '50%',
                                    border: `1px solid ${colors.primaryBlue}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: colors.primaryBlue,
                                    color: 'white',
                                    width: '20px',
                                    height: '20px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <IonIcon icon={checkmarkOutline} />
                            </div>
                        ) :

                            <div
                                slot='end'
                                style={{
                                    borderRadius: '50%',
                                    border: `1px solid ${colors.primaryBlue}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: "white",
                                    color: 'white',
                                    width: '20px',
                                    height: '20px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >

                            </div>
                        }
                    </IonItem>
                ))}
            </IonList>
        </div>
    );
};

export default StylistsFilter; 