// src/components/ResourceCalendarDay.jsx
import React from 'react';
import { IonContent } from '@ionic/react';
import '@ionic/react/css/core.css';
import CalendarHeader from './CalendarHeader';
import TimeLabels from './TimeLabels';
import EmployeeColumn from './EmployeeColumn';
import { parseTimeString, assignOverlapPositions, getEmployeeEvents } from './calendarUtils';
import CalendarSkeleton from './CalendarSkeleton';
import StoreClosedCard from '../CalendarDayView/StoreClosedCard';

const ResourceCalendarDay = ({breaks=[], appointments=[], employees=[], locationHours, isLoading, isStoreClosed, selectedDate, refreshAppointments, filteredBarbers, selectedStylists, handleStylistChange, businessId}) => {
  // Configurable variables:
  const employeeMinWidth = 270;
  const minHourHeight = 50;


  const locationStartTimeStr = locationHours?.openTime;
  const locationEndTimeStr = locationHours?.closeTime;
  const locationStartMinutes = parseTimeString(locationStartTimeStr);
  const locationEndMinutes = parseTimeString(locationEndTimeStr);
  
  // Calculate calendar start and end hours based on location hours
  const calendarStartHour = Math.max(0, Math.floor(locationStartMinutes / 60) - 1);
  const calendarEndHour = Math.min(24, Math.ceil(locationEndMinutes / 60) + 2);

  // Rest of the calculations using these values
  const minutesPerDay = (calendarEndHour - calendarStartHour) * 60;
  const calendarStartMinutes = calendarStartHour * 60;
  const calendarEndMinutes = calendarEndHour * 60;
  const headerRowHeight = 40;

  const [scaleFactor, setScaleFactor] = React.useState(1);
  React.useEffect(() => {
    const updateScale = () => {
      const availableHeight = window.innerHeight - 56;
      const timeGridAvailable = availableHeight - headerRowHeight;
      const computedScale = timeGridAvailable / minutesPerDay;
      const minScale = minHourHeight / 60;
      const newScale = Math.max(computedScale, minScale);
      setScaleFactor(newScale);
    };
    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, [minutesPerDay, headerRowHeight, minHourHeight]);

  const totalCalendarHeight = headerRowHeight + minutesPerDay * scaleFactor;
  const closedBeforeHeight = (locationStartMinutes - calendarStartMinutes) * scaleFactor;
  const closedAfterHeight = (calendarEndMinutes - locationEndMinutes) * scaleFactor;
  const closedAfterTop = (locationEndMinutes - calendarStartMinutes) * scaleFactor;
  // Status styles for appointments.
  const statusStyles = {
    Scheduled: {
      backgroundColor: "rgba(213, 217, 235, 0.60)",
      textColor: "#525252",
    },
    Arrived: {
      backgroundColor: "#fff",
      border: "2px dotted #0068DE",
      textColor: "#0068DE",
    },
    "In Progress": {
      backgroundColor: "rgba(194, 222, 255, 0.90)",
      textColor: "#406A8C",
    },
    Complete: {
      backgroundColor: "rgba(169, 239, 220, 0.60)",
      textColor: "#3B8C75",
    },
    Canceled: {
      backgroundColor: "#fff",
      border: "1px dashed #d3d3d3",
      textColor: "#d3d3d3",
    },
    "No Show": {
      backgroundColor: "#fff",
      border: "1px dashed #d3d3d3",
      textColor: "#d3d3d3",
    },
  };

  if(isStoreClosed){
    return <StoreClosedCard/>
  }


  if(employees.length === 0) {
    return <div>No employees found</div>
  }

  return (

      <IonContent>
        {isLoading ? (
          <CalendarSkeleton />
        ) : (
          <>
            <style>
              {`
                .hide-scrollbar {
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                }
                .hide-scrollbar::-webkit-scrollbar {
                  display: none;
                }
              `}
            </style>
            <div className="hide-scrollbar" style={{ overflow: 'auto', height: '100%' }}>
              <div
                className="calendar-grid"
                style={{
                  display: 'grid',
                  gridTemplateColumns: `50px repeat(${employees.length}, minmax(${employeeMinWidth}px, 1fr))`,
                  gridTemplateRows: `${headerRowHeight}px ${minutesPerDay * scaleFactor}px`,
                  height: totalCalendarHeight,
                  position: 'relative',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  backgroundColor: "#fff",
                }}
              >
                <CalendarHeader 
                  employees={employees} 
                  appointments={appointments}
                  selectedDate={selectedDate}
                  refreshAppointments={refreshAppointments}
                />
                <TimeLabels calendarStartHour={calendarStartHour} calendarEndHour={calendarEndHour} scaleFactor={scaleFactor} />
                {employees.map((employee, index) => {
                  const events = getEmployeeEvents(employee._id, appointments, breaks);
                  const positionedEvents = assignOverlapPositions(events);
                  return (
                    <div key={employee._id} style={{ gridColumn: `${index + 2} / ${index + 3}`, gridRow: '2 / 3' }}>
                      <EmployeeColumn
                        employee={employee}
                        events={positionedEvents}
                        scaleFactor={scaleFactor}
                        calendarStartMinutes={calendarStartMinutes}
                        calendarEndMinutes={calendarEndMinutes}
                        closedBeforeHeight={closedBeforeHeight}
                        closedAfterTop={closedAfterTop}
                        closedAfterHeight={closedAfterHeight}
                        statusStyles={statusStyles}
                        selectedDate={selectedDate}
                        refreshAppointments={refreshAppointments}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </IonContent>

  );
};

export default ResourceCalendarDay;
