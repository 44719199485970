import React, { useState, useEffect } from 'react';
import {
    IonItem,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonText,
    IonIcon,
    IonRadioGroup,
    IonRadio,
    IonPage,
    IonContent,
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import './CreateAccountForm.css';

const COLORS = [
    { value: '#FF385C', name: 'Red' },
    { value: '#00A699', name: 'Teal' },
    { value: '#FF8C00', name: 'Orange' },
    { value: '#1E90FF', name: 'Blue' },
    { value: '#914669', name: 'Purple' },
    { value: '#767676', name: 'Gray' },
];

const CreateAccountForm = ({ onSubmit, onBack, isFirstAccount = false }) => {
    const [name, setName] = useState('');
    const [access, setAccess] = useState('professional');
    const [color, setColor] = useState('#FF385C');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Trigger the fade-in animation after component mounts
        requestAnimationFrame(() => {
            setIsVisible(true);
        });
    }, []);

    const handleSubmit = () => {
        onSubmit({
            name,
            default: isFirstAccount,
            access,
            color
        });
    };

    return (
        <>
                {onBack && (
                    <div className={`back-button ${isVisible ? 'visible' : ''}`} style={{
                        position: 'absolute',
                        left: '4px',
                        top: '0px',
                    }}>
                        <IonButton
                            fill="clear"
                            onClick={onBack}
                            style={{
                                '--padding-start': '4px',
                                '--padding-end': '4px',
                                '--color': '#222222',
                            }}
                        >
                            <IonIcon icon={chevronBack} style={{ fontSize: '24px' }} /> Back
                        </IonButton>
                    </div>
                )}
                <div className={`create-account-form ${isVisible ? 'visible' : ''}`} style={{
                    padding: '0 20px',
                    maxWidth: '600px',
                    margin: '0 auto',
                    position: 'relative'
                }}>
                    <div style={{
                        marginBottom: '32px',
                        textAlign: 'center'
                    }}>
                        <h1 style={{
                            fontSize: '32px',
                            fontWeight: '600',
                            marginBottom: '8px',
                            color: '#222222',
                            marginTop: '60px'
                        }}>
                            {isFirstAccount ? 'Create a Cash Account' : 'Add New Account'}
                        </h1>
                        <IonText style={{
                            fontSize: '16px',
                            color: '#717171',
                            lineHeight: '1.4'
                        }}>
                            {isFirstAccount
                                ? 'Set up your first cash account to start tracking cash flow'
                                : 'Add another account to track different cash flows'
                            }
                        </IonText>
                    </div>

                    <div style={{ marginBottom: '24px' }}>
                        <div style={{
                            marginBottom: '8px',
                            fontSize: '15px',
                            fontWeight: '500',
                            color: '#222222'
                        }}>
                            Account Name
                        </div>
                        <IonItem lines="none" style={{
                            '--background': 'transparent',
                            '--border-color': '#b0b0b0',
                            '--border-style': 'solid',
                            '--border-width': '1px',
                            '--border-radius': '8px',
                            '--padding-start': '16px',
                            '--padding-end': '16px',
                            marginTop: '4px'
                        }}>
                            <IonInput
                                value={name}
                                onIonChange={e => setName(e.detail.value)}
                                placeholder="Enter account name"
                                style={{
                                    '--placeholder-color': '#717171',
                                    '--placeholder-opacity': '1'
                                }}
                            />
                        </IonItem>
                    </div>

                    <div style={{ marginBottom: '24px' }}>
                        <div style={{
                            marginBottom: '8px',
                            fontSize: '15px',
                            fontWeight: '500',
                            color: '#222222'
                        }}>
                            Account Color
                        </div>
                        <div style={{
                            display: 'flex',
                            gap: '12px',
                            flexWrap: 'wrap',
                            padding: '4px'
                        }}>
                            {COLORS.map((colorOption) => (
                                <div
                                    key={colorOption.value}
                                    onClick={() => setColor(colorOption.value)}
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        backgroundColor: colorOption.value,
                                        cursor: 'pointer',
                                        border: color === colorOption.value ? '2px solid #000' : '2px solid transparent',
                                        transition: 'transform 0.2s',
                                        transform: color === colorOption.value ? 'scale(1.1)' : 'scale(1)',
                                    }}
                                    title={colorOption.name}
                                />
                            ))}
                        </div>
                    </div>

                    <div style={{ marginBottom: '32px' }}>
                        <div style={{
                            marginBottom: '8px',
                            fontSize: '15px',
                            fontWeight: '500',
                            color: '#222222'
                        }}>
                            Access Level
                        </div>
                        <IonItem lines="none" style={{
                            '--background': 'transparent',
                            '--border-color': '#b0b0b0',
                            '--border-style': 'solid',
                            '--border-width': '1px',
                            '--border-radius': '8px',
                            '--padding-start': '16px',
                            '--padding-end': '16px',
                            marginTop: '4px'
                        }}>
                            <IonSelect
                                value={access}
                                onIonChange={e => setAccess(e.detail.value)}
                                interface="popover"
                                style={{
                                    width: '100%',
                                    '--placeholder-color': '#717171',
                                    '--placeholder-opacity': '1'
                                }}
                            >
                                <IonSelectOption value="professional">Professional</IonSelectOption>
                                <IonSelectOption value="manager">Manager</IonSelectOption>
                                <IonSelectOption value="admin">Admin</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <div style={{
                            marginTop: '8px',
                            fontSize: '14px',
                            color: '#717171'
                        }}>
                            This will be the lowest level of access for this account.
                        </div>
                    </div>

                    <IonButton
                        expand="block"
                        onClick={handleSubmit}
                        style={{
                            '--background': color,
                            '--background-hover': color,
                            '--background-activated': color,
                            '--border-radius': '8px',
                            '--box-shadow': 'none',
                            height: '48px',
                            fontSize: '16px',
                            fontWeight: '500',
                            margin: '0',
                            opacity: 0.9
                        }}
                    >
                        {isFirstAccount ? 'Create Account' : 'Add Account'}
                    </IonButton>

                    {isFirstAccount && (
                        <div style={{
                            marginTop: '24px',
                            textAlign: 'center',
                            fontSize: '14px',
                            color: '#717171'
                        }}>
                            This will be set as your default cash account
                        </div>
                    )}
                </div>
           </>
    );
};

export default CreateAccountForm; 