import React from 'react'
import { IonSkeletonText } from '@ionic/react'
import { colors } from '../../theme/colors'

const CalendarSkeleton = () => {
  // Create time slots from 9am to 9pm (typical business hours)
  const timeSlots = Array.from({ length: 13 }, (_, i) => i + 9)
  const employeeColumns = Array(4).fill(null)
  
  const employeeMinWidth = 250
  const headerRowHeight = 40

  return (
    <div className="hide-scrollbar" style={{ overflow: 'auto', height: '100%' }}>
      <div
        className="calendar-grid"
        style={{
          display: 'grid',
          gridTemplateColumns: `50px repeat(${employeeColumns.length}, minmax(${employeeMinWidth}px, 1fr))`,
          gridTemplateRows: `${headerRowHeight}px 1fr`,
          height: '100%',
          position: 'relative',
          fontFamily: 'Helvetica, Arial, sans-serif',
          backgroundColor: "#fff",
        }}
      >
        {/* Header Row */}
        <div style={{ 
          gridColumn: '1 / 2', 
          gridRow: '1 / 2',
          borderBottom: `1px solid ${colors.black10}`,
          borderRight: `1px solid ${colors.black10}`,
        }} />
        
        {/* Employee Headers */}
        {employeeColumns.map((_, index) => (
          <div
            key={`header-${index}`}
            style={{
              gridColumn: `${index + 2} / ${index + 3}`,
              gridRow: '1 / 2',
              padding: '8px',
              borderBottom: `1px solid ${colors.black10}`,
              borderRight: `1px solid ${colors.black10}`,
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <IonSkeletonText
              animated
              style={{
                width: '32px',
                height: '32px',
                borderRadius: '50%'
              }}
            />
            <IonSkeletonText
              animated
              style={{
                width: '60%',
                height: '16px'
              }}
            />
          </div>
        ))}

        {/* Time Labels Column */}
        <div style={{ 
          gridColumn: '1 / 2', 
          gridRow: '2 / 3',
          borderRight: `1px solid ${colors.black10}`,
          display: 'flex',
          flexDirection: 'column'
        }}>
          {timeSlots.map((hour) => (
            <div
              key={`time-${hour}`}
              style={{
                height: '60px',
                borderBottom: `1px solid ${colors.black10}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <IonSkeletonText
                animated
                style={{
                  width: '30px',
                  height: '16px'
                }}
              />
            </div>
          ))}
        </div>

        {/* Employee Columns */}
        {employeeColumns.map((_, colIndex) => (
          <div
            key={`col-${colIndex}`}
            style={{
              gridColumn: `${colIndex + 2} / ${colIndex + 3}`,
              gridRow: '2 / 3',
              borderRight: `1px solid ${colors.black10}`,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* Hour divider lines */}
            {timeSlots.map((hour) => (
              <div
                key={`divider-${colIndex}-${hour}`}
                style={{
                  height: '60px',
                  borderBottom: `1px solid ${colors.black10}`
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CalendarSkeleton 