import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
  IonText,
  useIonViewWillEnter,
  IonChip,
  IonSelect,
  IonSelectOption,
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonButton,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonPopover
} from '@ionic/react';
import { caretDown, refreshOutline, add, chevronBack, wallet, walletOutline, ellipsisVertical } from 'ionicons/icons';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import StandardContainer from '../commonComponents/StandardContainer';
import AddEntryModal from './AddEntryModal';
import NoteDetailsModal from './NoteDetailsModal';
import DailyCheckinModal from './DailyCheckinModal';
import './AccountDetails.css';

// Local storage key
const CASH_MANAGEMENT_GROUPING_KEY = 'cashManagementGrouping';

// Get saved grouping preference from local storage
const getSavedGrouping = () => {
  try {
    const saved = localStorage.getItem(CASH_MANAGEMENT_GROUPING_KEY);
    return saved || 'none';
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return 'none';
  }
};

const getTypeColor = (type) => {
  switch (type) {
    case 'Cash In':
      return 'var(--ion-color-success)';
    case 'Cash Out':
      return 'var(--ion-color-danger)';
    case 'Audit':
      return 'var(--ion-color-primary)';
    default:
      return 'var(--ion-color-primary)';
  }
};

// Save grouping preference to local storage
const saveGrouping = (grouping) => {
  try {
    localStorage.setItem(CASH_MANAGEMENT_GROUPING_KEY, grouping);
  } catch (error) {
    console.error('Error saving to localStorage:', error);
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const formatDateRange = (startDate, endDate, groupBy) => {
  const start = new Date(parseInt(startDate));

  if (groupBy === 'days') {
    return start.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  } else if (groupBy === 'weeks') {
    return `Week of ${start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
  } else if (groupBy === 'months') {
    return start.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  }
  return '';
};

const groupTransactions = (items, groupBy) => {
  const groups = items.reduce((groups, item) => {
    const date = new Date(item.date);
    let key;

    if (groupBy === 'days') {
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0);
      key = startOfDay.getTime();
    } else if (groupBy === 'weeks') {
      const startOfWeek = new Date(date);
      startOfWeek.setHours(0, 0, 0, 0);
      startOfWeek.setDate(date.getDate() - date.getDay());
      key = startOfWeek.getTime();
    } else if (groupBy === 'months') {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      startOfMonth.setHours(0, 0, 0, 0);
      key = startOfMonth.getTime();
    }

    if (!groups[key]) {
      groups[key] = {
        transactions: [],
        startDate: new Date(key),
        total: 0
      };
    }

    groups[key].transactions.push(item);
    groups[key].total += item.value;

    return groups;
  }, {});

  // Sort transactions within each group by date (most recent first)
  Object.values(groups).forEach(group => {
    group.transactions.sort((a, b) => b.date - a.date);
  });

  // Sort the groups themselves by date (most recent first)
  const sortedGroups = {};
  Object.keys(groups)
    .sort((a, b) => b - a)  // Sort keys in reverse chronological order
    .forEach(key => {
      sortedGroups[key] = groups[key];
    });

  return sortedGroups;
};

const formatBarberName = (barber) => {
  if (!barber) return '';
  const lastInitial = barber?.lastName ? barber?.lastName.charAt(0) : '';
  const fullName = `${barber?.firstName}${lastInitial ? ` ${lastInitial}.` : ''}`;

  // Truncate if longer than 20 characters
  return fullName.length > 20 ? fullName.substring(0, 17) + '...' : fullName;
};

// Add helper function to calculate discrepancy
const getAuditDiscrepancy = (expectedBalance, auditValue) => {
  if (expectedBalance === null) return null;
  const discrepancy = auditValue - expectedBalance;
  return {
    amount: Math.abs(discrepancy).toFixed(2),
    type: discrepancy < 0 ? 'shortage' : discrepancy > 0 ? 'overage' : 'match',
    previousExpectedAmount: expectedBalance
  };
};

// Update the calculateRunningBalance function to include discrepancy info
const calculateRunningBalance = (items) => {
  const sortedItems = [...items].sort((a, b) => a.date - b.date);

  let currentAuditAmount = 0;
  let runningBalance = 0;
  let lastAuditIndex = -1;

  // Find the first audit and its index
  for (let i = 0; i < sortedItems.length; i++) {
    if (sortedItems[i].itemType === 'note' && sortedItems[i].type === 'Audit') {
      currentAuditAmount = sortedItems[i].value;
      runningBalance = currentAuditAmount;
      lastAuditIndex = i;
      break;
    }
  }

  // Calculate running balance for each item
  return sortedItems.map((item, index) => {
    if (index <= lastAuditIndex) {
      return {
        ...item,
        expectedBalance: index === lastAuditIndex ? currentAuditAmount : null,
        discrepancy: null
      };
    }

    let discrepancy = null;

    if (item.itemType === 'note') {
      if (item.type === 'Audit') {
        discrepancy = getAuditDiscrepancy(runningBalance, item.value);
        currentAuditAmount = item.value;
        runningBalance = item.value;
      } else if (item.type === 'Cash In') {
        runningBalance += item.value;
      } else if (item.type === 'Cash Out') {
        runningBalance -= Math.abs(item.value);
      }
    } else if (item.itemType === 'transaction') {
      runningBalance += item.value;
    }

    return {
      ...item,
      expectedBalance: runningBalance,
      discrepancy
    };
  });
};

const modalStyles = {
  header: {
    '--background': 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #eee'
  },
  toolbar: {
    '--background': 'white'
  },
  backButton: {
    color: '#222222'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#222222',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  walletIcon: (color) => ({
    color: color || '#222222',
    fontSize: '20px',
    marginBottom: '-5px',
    marginRight: '4px'
  }),
  headerContainer: {
    margin: 20, 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'flex-start'
  },
  groupSelect: {
    marginTop: 16
  },
  groupSelectContainer: {
    backgroundColor: '#d9d9d9', 
    borderRadius: 30, 
    padding: 0, 
    paddingLeft: 10, 
    paddingRight: 10, 
    width: 'fit-content'
  },
  select: {
    maxWidth: '100%', 
    padding: '0 8px'
  },
  errorContainer: {
    padding: '0 20px 20px', 
    color: 'var(--ion-color-danger)'
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px 24px',
    textAlign: 'center',
    minHeight: '60vh'
  },
  emptyStateIcon: (color) => ({
    fontSize: '48px',
    color: color || '#FF385C', // Use account color or fallback to Airbnb red
    marginBottom: '24px'
  }),
  emptyStateTitle: {
    fontSize: '22px',
    fontWeight: '600',
    color: '#222222',
    marginBottom: '12px',
    lineHeight: '26px'
  },
  emptyStateText: {
    fontSize: '15px',
    color: '#717171',
    marginBottom: '32px',
    lineHeight: '20px',
    maxWidth: '300px'
  },
  addButton: (color) => ({
    '--background': color || '#FF385C',
    '--background-hover': color ? `${color}dd` : '#E31C5F',
    '--background-activated': color ? `${color}dd` : '#E31C5F',
    '--border-radius': '8px',
    '--padding-start': '24px',
    '--padding-end': '24px',
    '--padding-top': '12px',
    '--padding-bottom': '12px',
    fontWeight: '500',
    fontSize: '16px',
    textTransform: 'none',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
  }),
  desktopButtons: {
    display: 'flex'
  },
  mobileMenuButton: {
    display: 'none'
  }
}

const CashManagement = () => {
  const history = useHistory();
  const { id } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupBy, setGroupBy] = useState(getSavedGrouping());
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [account, setAccount] = useState(null);
  const [dailyCheckinModalOpen, setDailyCheckinModalOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const handleGroupingChange = (value) => {
    setGroupBy(value);
    saveGrouping(value);
  };

  const fetchData = async (showLoading = true) => {
    if (id) {
      if (showLoading) setLoading(true);
      setError(null);
      try {
        // const [transactionsResponse, notesResponse] = await Promise.all([
        //   axios.get('/appointment_v2/cashTransactions'),
        //   axios.get(`/cashManagement/getList?accountId=${id}`)
        // ]);

        let cashManagementResponse = await axios.get(`/cashManagement/getList?accountId=${id}`);
        let transactionsResponse = cashManagementResponse.data.transactions;
        let notesResponse = cashManagementResponse.data.notes;
        let accountResponse = cashManagementResponse.data.account;

        // Filter out subscription transactions
        const filteredTransactions = transactionsResponse.filter(
          transaction => !transaction.customer?.subscription
        );

        setTransactions(filteredTransactions);
        setNotes(notesResponse);
        setAccount(accountResponse);
      } catch (err) {
        setError('Failed to load cash management data');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRefresh = async (event) => {
    await fetchData(false);
    event.detail.complete();
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useIonViewWillEnter(() => {
    fetchData();
  });

  const handleTransactionClick = (appointmentId) => {
    history.push(`/core/appointments/${appointmentId}`);
  };

  // Update the combineItems function
  const combineItems = (transactions, notes, groupBy) => {
    // Convert transactions to common format
    const formattedTransactions = transactions.map(t => ({
      ...t,
      itemType: 'transaction',
      date: new Date(t.date).getTime(),
      value: t.payment?.totalPrice - (t.payment?.cashTipAmount || 0)
    }));

    // Convert notes to common format
    const formattedNotes = notes.map(n => ({
      ...n,
      itemType: 'note',
      date: new Date(n.date).getTime(),
      value: (n.type === 'Cash In' || n.type === 'Audit') ? n.value : -n.value
    }));

    // Combine all items
    const allItems = [...formattedTransactions, ...formattedNotes];
    const itemsWithBalance = calculateRunningBalance(allItems);

    if (groupBy === 'none') {
      return {
        ungrouped: itemsWithBalance.sort((a, b) => b.date - a.date)
      };
    }

    return groupTransactions(itemsWithBalance, groupBy);
  };

  // Update the renderNoteItem function to show discrepancy
  const renderNoteItem = (note, index, isLast = false) => (
    <IonItem
      key={`note-${index}`}
      lines={isLast ? "none" : "full"}
      button
      onClick={() => setSelectedNote(note)}
    >
      <IonLabel>
        <h2>
          {note.type === 'Cash In' ? (
            <span style={{ color: 'var(--ion-color-success)' }}>Cash In</span>
          ) : note.type === 'Cash Out' ? (
            <span style={{ color: 'var(--ion-color-danger)' }}>Cash Out</span>
          ) : note.type === 'Audit' ? (
            <span style={{ color: 'var(--ion-color-primary)' }}>Cash Count (Audit)</span>
          ) : null}
          {note.note && (
            <span style={{
              color: 'var(--ion-color-medium)',
              fontSize: '0.9em'
            }}>
              {note.note}
            </span>
          )}
        </h2>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{formatDate(note.date)}</span>
          <div style={{ textAlign: 'right' }}>
            <div style={{
              fontWeight: '600',
              color: getTypeColor(note.type)
            }}>
              {(note.type === 'Cash Out' ? '-' : note.type === 'Cash In' ? '+' : "")}
              ${Math.abs(note.value).toFixed(2)}
            </div>
            {note.expectedBalance !== null && (
              <div style={{
                fontSize: '0.8em',
                color: note.discrepancy?.type === 'shortage' ? 'var(--ion-color-danger)' :
                  note.discrepancy?.type === 'overage' ? 'var(--ion-color-warning)' :
                    'var(--ion-color-medium)',
                marginTop: '4px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}>
                Cash Balance: ${note.expectedBalance.toFixed(2)}
                {note.discrepancy && note.discrepancy.type !== 'match' && (
                  <span style={{
                    fontWeight: '500',
                    marginTop: '2px'
                  }}>
                    {note.discrepancy.type === 'shortage' ? 'Short' : 'Over'}: $
                    {note.discrepancy.amount}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </IonLabel>
    </IonItem>
  );

  // Update the renderTransactionItem function to show the balance
  const renderTransactionItem = (transaction, index, isLast = false) => (
    <IonItem
      key={`transaction-${index}`}
      button
      lines={isLast ? "none" : "full"}
      onClick={() => handleTransactionClick(transaction._id)}
    >
      <IonLabel>
        <h2>
          {transaction.customer?.firstName} {transaction.customer?.lastName}
          <span style={{ float: 'right', color: 'var(--ion-color-medium)' }}>
            {formatBarberName(transaction.barber)}
          </span>
        </h2>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{formatDate(transaction.date)}</span>
          <div style={{ textAlign: 'right' }}>
            <div style={{ fontWeight: '600' }}>
              ${transaction.value.toFixed(2)}
            </div>
            {transaction.expectedBalance !== null && (
              <div style={{
                fontSize: '0.8em',
                color: 'var(--ion-color-medium)',
                marginTop: '4px'
              }}>
                Expected: ${transaction.expectedBalance.toFixed(2)}
              </div>
            )}
          </div>
        </div>
      </IonLabel>
    </IonItem>
  );

  const renderHeader = () => (
    <>
      <div style={modalStyles.headerContainer}>
        <div>
          <div style={modalStyles.groupSelect}>
            <div style={modalStyles.groupSelectContainer}>
              <IonSelect
                value={groupBy}
                onIonChange={e => handleGroupingChange(e.detail.value)}
                interface="popover"
                style={modalStyles.select}
              >
                <IonSelectOption value="none">No Groups - All Transactions</IonSelectOption>
                <IonSelectOption value="days">Days</IonSelectOption>
                <IonSelectOption value="weeks">Weeks</IonSelectOption>
                <IonSelectOption value="months">Months</IonSelectOption>
              </IonSelect>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div style={modalStyles.errorContainer}>
          <IonText color="danger">{error}</IonText>
        </div>
      )}
    </>
  );

  const renderEmptyState = () => (
    <div style={modalStyles.emptyState}>
      <IonIcon 
        icon={walletOutline} 
        style={modalStyles.emptyStateIcon(account?.color)}
      />
      <h2 style={modalStyles.emptyStateTitle}>
        No entries yet
      </h2>
      <p style={modalStyles.emptyStateText}>
        Start tracking your cash flow by adding your first entry
      </p>
      <IonButton 
        style={modalStyles.addButton(account?.color)}
        onClick={() => setIsAddModalOpen(true)}
        expand="block"
      >
        Add your first entry
      </IonButton>
    </div>
  );

  // if (loading) {
  //   return (
  //     <IonPage>
  //       <IonHeader style={modalStyles.header}>
  //         <IonToolbar style={modalStyles.toolbar}>
  //           <IonButtons slot="start">
  //             <IonBackButton defaultHref="/core/cashManagement" style={modalStyles.backButton}/>
  //           </IonButtons>
  //           <IonTitle style={modalStyles.title}>
  //             {account?.name || 'Account Details'}
  //           </IonTitle>
  //         </IonToolbar>
  //       </IonHeader>
  //       <IonContent>
  //         <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
  //           <IonRefresherContent
  //             pullingIcon={refreshOutline}
  //             pullingText="Pull to refresh"
  //             refreshingSpinner="circles"
  //             refreshingText="Refreshing...">
  //           </IonRefresherContent>
  //         </IonRefresher>

  //         <StandardContainer>
  //           {renderHeader()}
  //           <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
  //             <IonSpinner />
  //           </div>
  //         </StandardContainer>
  //       </IonContent>
  //     </IonPage>
  //   );
  // }

  const groupedItems = combineItems(transactions, notes, groupBy);
  console.log("dailyCheckinModalOpen", dailyCheckinModalOpen)
  const openPopover = (e) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  return (
    <IonPage>
      <IonHeader style={modalStyles.header}>
        <IonToolbar style={modalStyles.toolbar}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/core/cashManagement" style={modalStyles.backButton}/>
          </IonButtons>
          <IonTitle style={modalStyles.title}>
            <IonIcon 
              icon={wallet} 
              style={modalStyles.walletIcon(account?.color)} 
            />
            {account?.name || 'Account Details'}
          </IonTitle>
          <IonButtons slot="end">
            <div className="desktop-buttons" style={modalStyles.desktopButtons}>
              <IonButton onClick={() => setIsAddModalOpen(true)}>
                <IonIcon icon={add} slot="start" />
                Add Entry
              </IonButton>
              <IonButton onClick={() => setDailyCheckinModalOpen(true)}>
                <IonIcon icon={wallet} slot="start" />
                Daily Checkin
              </IonButton>
            </div>
            <div className="mobile-menu" style={modalStyles.mobileMenuButton}>
              <IonButton onClick={openPopover}>
                <IonIcon slot="icon-only" icon={ellipsisVertical} />
              </IonButton>
            </div>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent
            pullingIcon={refreshOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing...">
          </IonRefresherContent>
        </IonRefresher>

        <StandardContainer>
          {renderHeader()}
          
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <IonSpinner />
            </div>
          ) : !error && transactions.length === 0 && notes.length === 0 ? (
            renderEmptyState()
          ) : (
            groupBy === 'none' ? (
              <IonList>
                {groupedItems.ungrouped.map((item, index) =>
                  item.itemType === 'note'
                    ? renderNoteItem(item, index, index === groupedItems.ungrouped.length - 1)
                    : renderTransactionItem(item, index, index === groupedItems.ungrouped.length - 1)
                )}
              </IonList>
            ) : (
              <IonAccordionGroup expand="inset">
                {Object.entries(groupedItems)
                  .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
                  .map(([date, group]) => (
                    <IonAccordion key={date} value={date}>
                      <IonItem slot="header">
                        <IonLabel>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <strong>{formatDateRange(date, date, groupBy)}</strong>
                            <span>${group.total.toFixed(2)}</span>
                          </div>
                        </IonLabel>
                      </IonItem>

                      <div slot="content">
                        <IonList>
                          {group.transactions.map((item, index) =>
                            item.itemType === 'note'
                              ? renderNoteItem(
                                item,
                                index,
                                index === group.transactions.length - 1
                              )
                              : renderTransactionItem(
                                item,
                                index,
                                index === group.transactions.length - 1
                              )
                          )}
                        </IonList>
                      </div>
                    </IonAccordion>
                  ))}
              </IonAccordionGroup>
            )
          )}
        </StandardContainer>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonList>
          <IonItem button onClick={() => {
            setIsAddModalOpen(true);
            setShowPopover(false);
          }}>
            <IonIcon icon={add} slot="start" />
            <IonLabel>Add Entry</IonLabel>
          </IonItem>
          <IonItem button onClick={() => {
            setDailyCheckinModalOpen(true);
            setShowPopover(false);
          }}>
            <IonIcon icon={wallet} slot="start" />
            <IonLabel>Daily Checkin</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>

      <AddEntryModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSuccess={() => fetchData()}
        accountId={account?._id}
      />
      <NoteDetailsModal
        isOpen={!!selectedNote}
        onClose={() => setSelectedNote(null)}
        note={selectedNote}
      />
      <DailyCheckinModal
        isOpen={dailyCheckinModalOpen}
        onClose={() => setDailyCheckinModalOpen(false)}
        account={{
          ...account,
          notes,
          transactions
        }}
        onSuccess={()=>{}}
        fetchData={fetchData}
      />
    </IonPage>
  );
};

export default CashManagement; 