import React, { useContext } from 'react';
import { IonCard, IonRouterLink, IonRow, IonCol, IonIcon } from '@ionic/react';
import { colors } from '../theme/colors';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { useIonRouter } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';

const styles = {
    topCard: { padding: 10, margin: 0, minWidth: 160, color: 'black' },
};

export default function UnreadMessagesCard({ locationId, refreshValue }) {
    const [unreadMessages, setUnreadMessages] = useState({ data: [], loading: false, error: null });
    const { userData } = useContext(AuthContext);
    const router = useIonRouter();

    const getUnreadMessages = async () => {
        if (!locationId) return;

        setUnreadMessages(prev => ({ ...prev, loading: true, error: null }));
        try {
            const response = await axios.get(`/conversation/unread_messages?locationId=${locationId}`);
            setUnreadMessages({ loading: false, data: response.data, error: null });
        } catch (error) {
            setUnreadMessages(prev => ({ ...prev, loading: false, error: 'Failed to fetch unread messages' }));
        }
    };

    // Initial load and refresh handling
    useEffect(() => {
        getUnreadMessages();
    }, [locationId, refreshValue, userData]);

    // Polling every 20 seconds
    useEffect(() => {
        if (!locationId || !userData) return;

        const interval = setInterval(() => {
            getUnreadMessages();
        }, 20000);

        // Cleanup interval on unmount or when dependencies change
        return () => clearInterval(interval);
    }, [locationId, userData]);

    const formatMessageTime = (dateString) => {
        const messageDate = new Date(dateString);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);

        // Check if message is from today
        if (messageDate.toDateString() === now.toDateString()) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        // Check if message is from yesterday
        if (messageDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        }

        // Otherwise return date
        return messageDate.toLocaleDateString([], {
            month: 'numeric',
            day: 'numeric',
            year: '2-digit'
        });
    };

    if (unreadMessages.loading && !unreadMessages.data.length) {
        return null;
    }

    if (unreadMessages.error) {
        return null;
    }

    if (!unreadMessages.data.length) {
        return null;
    }

    return (
        <IonRow>
            <IonCol>
                <div style={styles.topCard} className='flat'>
                    <h6 style={{ margin: 0, color: 'black', marginBottom: 15 }}>Unread Messages</h6>
                    {unreadMessages.data.map((message) => (
                        <div
                            key={message.twilioConversationId}
                            style={{
                                padding: '12px',
                                borderBottom: `1px solid ${colors.lightGrey}`,
                                cursor: 'pointer',
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'flex-start',
                                borderRadius: '10px',
                                border: `1px solid ${colors.lightGrey}`,
                            }}
                            onClick={() => {
                                router.push(`/core/messages/${message.customerId}`);
                                setTimeout(() => {
                                    getUnreadMessages();
                                }, 5000);
                            }}
                        >
                            {/* Unread Dot */}
                            <div style={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: colors.primaryBlue,
                                flexShrink: 0,
                                marginTop: '16px' // Center with avatar
                            }} />

                            {/* Avatar Circle */}
                            <div style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: colors.lightGrey,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexShrink: 0,
                                fontSize: '18px',
                                color: colors.grey
                            }}>
                                {message.customerFirstName?.[0]}{message.customerLastName?.[0]}
                            </div>

                            {/* Content Container */}
                            <div style={{ flex: 1, minWidth: 0 }}>
                                {/* Header Row */}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '4px'
                                }}>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {message.customerFirstName} {message.customerLastName}
                                    </span>
                                    <div style={{
                                        color: colors.grey,
                                        fontSize: '0.8em',
                                        flexShrink: 0
                                    }}>
                                        {formatMessageTime(message.lastUnreadAt)}
                                    </div>
                                </div>

                                {/* Phone Number */}
                                {/* <div style={{ 
                            color: colors.grey, 
                            fontSize: '0.9em',
                            marginBottom: '2px'
                        }}>
                            {message.customerPhoneNumber}
                        </div> */}

                                {/* Message Preview */}
                                {message.lastMessage?.body && (
                                    <div style={{
                                        color: colors.grey,
                                        fontSize: '0.9em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {message.lastMessage.body}
                                    </div>
                                )}
                            </div>

                            {/* Chevron */}
                            <IonIcon
                                icon={chevronForward}
                                style={{
                                    color: colors.grey,
                                    fontSize: '20px',
                                    marginLeft: '8px',
                                    alignSelf: 'center'
                                }}
                            />
                        </div>
                    ))}
                </div>
            </IonCol>
        </IonRow>
    );
} 