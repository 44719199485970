import React, { useState, useContext, useEffect } from 'react'
import { IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonAvatar, IonItem, IonLabel, IonList, IonToast } from '@ionic/react'
import { AuthContext } from '../../auth'

import axios from 'axios'
import Avatar from '../../commonComponents/avatar'

function Example({ isOpen, setEmployeeModalOpen, locationId, appointmentId, updateAppointment }) {
  const { businessData, selectedLocationData } = useContext(AuthContext)
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  function dismiss() {
    setEmployeeModalOpen(false)
  }

  useEffect(() => {
    getEmployeesList()
  }, [])

  const getEmployeesList = async () => {
    if (!businessData?._id) return
    setLoading(true)
    setErrorMessage('')
    try {
      let response = await axios.get(`/employee/getList?businessId=${businessData._id}&locationId=${selectedLocationData?._id}`)
      setEmployees(response.data)
    } catch (error) {
      console.error('Error fetching employees:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateEmployee = async (id) => {
    setSaving(true)
    try {
      await axios.patch(`/appointment_v2/updateBarber`, { barberId: id, appointmentId })
      await updateAppointment()
      dismiss()
      setToast({ isOpen: true, message: 'Employee updated successfully.', color: 'success' })
    } catch (error) {
      setToast({ isOpen: true, message: 'Error saving employee. Please try again.', color: 'danger' })
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle>Change Professional</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => dismiss()}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='regularBackground'>
          <h6 style={{ color: 'grey', marginLeft: 20 }}>Employees</h6>
          <IonList inset={true} mode='ios'>
            {employees?.map((row) => (
              <IonItem
                key={row._id}
                button
                onClick={() => {
                  updateEmployee(row._id)
                }}
              >
                <IonAvatar slot='start' style={{ marginTop: 10, marginBottom: 10 }}>
                  <Avatar employee={row} businessId={businessData._id} small />
                </IonAvatar>
                <IonLabel>
                  <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.firstName} ${row.lastName}`}</h4>
                  {/* <p>{row.role}</p> */}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}

export default Example
