// src/components/TimeLabels.jsx
import React from 'react';

const TimeLabels = ({ calendarStartHour, calendarEndHour, scaleFactor }) => {
  return (
    <div style={{ position: 'sticky', left: 0, borderRight: '1px solid #ddd', backgroundColor: '#fff', zIndex: 4 }}>
      {Array.from({ length: calendarEndHour - calendarStartHour + 1 }, (_, i) => {
        const hour = calendarStartHour + i;
        const displayHour = hour === 12 ? 12 : hour > 12 ? hour - 12 : hour;
        const meridiem = hour < 12 ? 'AM' : 'PM';
        return (
          <div
            key={i}
            style={{
              position: 'absolute',
              top: i * 60 * scaleFactor,
              width: '100%',
              fontSize: '12px',
              textAlign: 'center',
              transform: 'translateY(-50%)',
            }}
          >
            {displayHour} {meridiem}
          </div>
        );
      })}
    </div>
  );
};

export default TimeLabels;
