import React, { useState, useEffect, useContext } from 'react'
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonMenuButton,
  IonProgressBar,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
} from '@ionic/react'
import { format, addWeeks, subWeeks, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, addDays, subDays } from 'date-fns'
import { chevronBack, chevronForward, today, arrowBack, closeOutline } from 'ionicons/icons'
import { AuthContext } from '../../auth'
import axios from 'axios'
import DefaultMenu from '../../commonComponents/SideMenu/DefaultMenu'
import StandardContainer from '../../commonComponents/StandardContainer'
import EmployeeHoursModal from '../../appointments/AI_Calendar/EmployeeHoursModal'
import BreakModal from '../../appointments/AI_Calendar/BreakModal'
import { colors } from '../../theme/colors'
import { fontSizes, fontWeights } from '../../theme/typography'
import MobileScheduleView from '../MobileScheduleView'
import Avatar from '../../commonComponents/avatar'
import ScheduleSummaryModal from '../ScheduleSummaryModal'
import ScheduleEditSection from '../ScheduleEditSection'

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [matches, query])

  return matches
}

const ShimmerCell = () => {
  return (
    <div style={{
      padding: '6px',
      borderRadius: '6px',
      backgroundColor: '#F1F8FF',
      height: '52px',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(90deg, #F1F8FF 0%, #E3F2FF 50%, #F1F8FF 100%)',
        backgroundSize: '200% 100%',
        animation: 'shimmer 2s infinite ease-in-out'
      }} />
      <style>
        {`
          @keyframes shimmer {
            0% {
              background-position: -200% 0;
            }
            100% {
              background-position: 200% 0;
            }
          }
        `}
      </style>
    </div>
  );
};

const TableHead = ({ weekDays }) => {
  return (
    <thead>
      <tr>
        <th
          style={{
            minWidth: '150px',
            width: '150px',
            backgroundColor: 'white',
            padding: '16px',
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 30,
            borderBottom: '1px solid #e5e7eb',
            borderRight: '1px solid #e5e7eb'
          }}
        >
          Employee
        </th>
        {weekDays.map(day => (
          <th
            key={day.toISOString()}
            style={{
              minWidth: '150px',
              width: '150px',
              backgroundColor: 'white',
              padding: '16px',
              position: 'sticky',
              top: 0,
              zIndex: 20,
              borderBottom: '1px solid #e5e7eb',
              borderRight: '1px solid #e5e7eb'
            }}
          >
            <div style={{ fontWeight: 'bold' }}>{format(day, 'EEEE')}</div>
            <div style={{ color: colors.black60 }}>{format(day, 'MMM d')}</div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

const ScheduleCell = ({ data, employee, date, onScheduleClick, onBreakClick, businessId, fetchScheduleData }) => {
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [editingSchedule, setEditingSchedule] = useState(false);
  const [editingBreak, setEditingBreak] = useState(null);

  if (!data) {
    return null;
  }

  const { schedule, breaks, isModified } = data;
  
  
  const handleScheduleClick = (e) => {
    e.stopPropagation();
    setEditingSchedule(true);
  };

  const handleBreakClick = (e, breakItem) => {
    e.stopPropagation();
    setEditingBreak(breakItem);
  };

  const handleScheduleSave = (data) => {
    onScheduleClick(employee, date, { schedule: { ...schedule, ...data }, breaks });
    setEditingSchedule(false);
  };

  const handleBreakSave = (data) => {
    onBreakClick(employee, date, { ...editingBreak, ...data });
    setEditingBreak(null);
  };

  const handleClose = () => {
    setEditingSchedule(false);
    setEditingBreak(null);
    setShowSummaryModal(false);
  };

  if (editingSchedule) {
    return (
      <IonModal isOpen={true} onDidDismiss={handleClose} style={{
        '--border-radius': '16px',
        '--width': '100%',
        '--max-width': '600px'
      }} backdropDismiss={false}>
        <IonContent>
          <StandardContainer padding={20}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h2 style={{ marginBottom: '4px' }}>
                    {employee?.firstName}'s Schedule
                  </h2>
                  <div style={{ 
                    fontSize: '15px',
                    color: colors.black60,
                    marginBottom: '8px'
                  }}>
                    {format(date, 'EEEE, MMMM d, yyyy')}
                  </div>
                </IonCol>
                <IonCol size='auto'>
                  <IonIcon
                    onClick={handleClose}
                    style={{ 
                      fontSize: fontSizes.size24, 
                      color: colors.black40, 
                      fontWeight: fontWeights.weightBold,
                      cursor: 'pointer'
                    }}
                    icon={closeOutline}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <ScheduleEditSection
              employee={employee}
              schedule={schedule}
              type="schedule"
              onSave={handleScheduleSave}
              onCancel={handleClose}
              fetchScheduleData={fetchScheduleData}
            />
          </StandardContainer>
        </IonContent>
      </IonModal>
    );
  }

  if (editingBreak) {
    return (
      <IonModal isOpen={true} onDidDismiss={handleClose} style={{
        '--border-radius': '16px',
        '--width': '100%',
        '--max-width': '600px'
      }} backdropDismiss={false}>
        <IonContent>
          <StandardContainer padding={20}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h2 style={{ marginBottom: '4px' }}>
                    {employee?.firstName}'s Break
                  </h2>
                  <div style={{ 
                    fontSize: '15px',
                    color: colors.black60,
                    marginBottom: '8px'
                  }}>
                    {format(date, 'EEEE, MMMM d, yyyy')}
                  </div>
                </IonCol>
                <IonCol size='auto'>
                  <IonIcon
                    onClick={handleClose}
                    style={{ 
                      fontSize: fontSizes.size24, 
                      color: colors.black40, 
                      fontWeight: fontWeights.weightBold,
                      cursor: 'pointer'
                    }}
                    icon={closeOutline}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <ScheduleEditSection
              employee={employee}
              type="break"
              breakData={editingBreak}
              onSave={handleBreakSave}
              onCancel={handleClose}
              fetchScheduleData={fetchScheduleData}
            />
          </StandardContainer>
        </IonContent>
      </IonModal>
    );
  }
  
  if (!schedule) {
    return (
      <div 
        onClick={handleScheduleClick}
        style={{ 
          padding: '6px',
          borderRadius: '6px',
          border: `1px dashed ${colors.black20}`,
          color: colors.black60,
          cursor: 'pointer',
          textAlign: 'center',
          fontSize: '0.85rem'
        }}
      >
        Add hours
      </div>
    );
  }
  
  if (schedule.enabled === false) {
    return (
      <div>
        <div 
          onClick={handleScheduleClick}
          style={{ 
            color: colors.black60,
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: '0.85rem',
            padding: '6px',
            borderRadius: '6px',
            transition: 'all 0.2s ease'
          }}
          onMouseEnter={e => {
            e.currentTarget.style.fontWeight = '600';
            e.currentTarget.style.backgroundColor = colors.black05;
          }}
          onMouseLeave={e => {
            e.currentTarget.style.fontWeight = 'normal';
            e.currentTarget.style.backgroundColor = 'transparent';
          }}
        >
          Day off
        </div>

        <div style={{ position: 'relative', height: '32px' }}>
          <div 
            onClick={() => setShowSummaryModal(true)}
            style={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '4px',
              borderRadius: '4px',
              color: colors.black60,
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.2s ease'
            }}
            onMouseEnter={e => {
              e.currentTarget.style.transform = 'translateX(-50%) scale(1.2)';
              e.currentTarget.style.color = colors.black90;
            }}
            onMouseLeave={e => {
              e.currentTarget.style.transform = 'translateX(-50%) scale(1)';
              e.currentTarget.style.color = colors.black60;
            }}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </div>
        </div>

        {showSummaryModal && (
          <ScheduleSummaryModal
            isOpen={showSummaryModal}
            onClose={() => setShowSummaryModal(false)}
            employee={employee}
            date={date}
            schedule={schedule}
            breaks={breaks}
            onScheduleClick={onScheduleClick}
            onBreakClick={onBreakClick}
            businessId={businessId}
            fetchScheduleData={fetchScheduleData}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <div 
        onClick={handleScheduleClick}
        style={{
          padding: '6px',
          borderRadius: '6px',
          border: `1px solid ${colors.black10}`,
          marginBottom: breaks?.length > 0 ? '4px' : 0,
          cursor: 'pointer',
          backgroundColor: '#F1F8FF',
          transition: 'all 0.2s ease'
        }}
        onMouseEnter={e => {
          e.currentTarget.style.transform = 'scale(1.02)';
          e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
        }}
        onMouseLeave={e => {
          e.currentTarget.style.transform = 'scale(1)';
          e.currentTarget.style.boxShadow = 'none';
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px'
        }}>
          <span style={{
            fontSize: '0.65rem',
            color: '#1565C0',
            fontWeight: '600'
          }}>
            Working Hours
          </span>
          <div style={{
            display: 'flex'
          }}>
            <span style={{ 
              fontSize: '0.8rem',
              color: '#1976D2'
            }}>
              {schedule.startTime} - {schedule.endTime}
            </span>
          </div>
        </div>
      </div>

      {breaks && breaks.length > 0 && (
        <div>
          {breaks.map(breakItem => (
            <div
              key={breakItem._id}
              onClick={(e) => handleBreakClick(e, breakItem)}
              style={{
                padding: '4px 6px',
                borderRadius: '4px',
                backgroundColor: '#F3E5F5',
                marginTop: '4px',
                border: `1px solid ${colors.black10}`,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                transition: 'all 0.2s ease'
              }}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'scale(1.02)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              <span style={{
                fontSize: '0.65rem',
                color: '#6A1B9A',
                fontWeight: '600'
              }}>
                Break
              </span>
              <span style={{
                fontSize: '0.8rem',
                color: '#6A1B9A'
              }}>
                {breakItem.startTime} - {breakItem.endTime}
              </span>
            </div>
          ))}
        </div>
      )}

      <div style={{ position: 'relative', height: '32px' }}>
        <div 
          onClick={() => setShowSummaryModal(true)}
          style={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '4px',
            borderRadius: '4px',
            color: colors.black60,
            cursor: 'pointer',
            textAlign: 'center',
            fontSize: '0.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.2s ease'
          }}
          onMouseEnter={e => {
            e.currentTarget.style.transform = 'translateX(-50%) scale(1.2)';
            e.currentTarget.style.color = colors.black90;
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = 'translateX(-50%) scale(1)';
            e.currentTarget.style.color = colors.black60;
          }}
        >
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </div>
      </div>

      {showSummaryModal && (
        <ScheduleSummaryModal
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          employee={employee}
          date={date}
          schedule={schedule}
          breaks={breaks}
          onScheduleClick={onScheduleClick}
          onBreakClick={onBreakClick}
          businessId={businessId}
          fetchScheduleData={fetchScheduleData}
        />
      )}
    </div>
  );
};

const SchedulePage = () => {
  const { businessData, locationId, selectedLocationData } = useContext(AuthContext)

  const [currentWeek, setCurrentWeek] = useState(new Date())
  const [employees, setEmployees] = useState([])
  const [loadingEmployees, setLoadingEmployees] = useState(false)
  const [loadingSchedule, setLoadingSchedule] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedBreak, setSelectedBreak] = useState(null)
  const [showHoursModal, setShowHoursModal] = useState(false)
  const [showBreakModal, setShowBreakModal] = useState(false)
  const [scheduleData, setScheduleData] = useState({
    standardSchedules: [],
    modifiedSchedules: [],
    breaks: []
  })
  const [mobileSelectedDate, setMobileSelectedDate] = useState(new Date())
  const isMobile = useMediaQuery('(max-width: 767px)')


  useEffect(() => {

    getEmployeesList()
  }, [businessData, selectedLocationData?._id])

  useEffect(() => {
    if (employees.length > 0) {

      fetchScheduleData()
    }
  }, [employees, currentWeek])

  const getEmployeesList = async () => {
    if (!businessData?._id || !selectedLocationData?._id) {
      return
    }
    setLoadingEmployees(true)
    try {
      const url = `/employee/getList?businessId=${businessData?._id}&locationId=${selectedLocationData?._id}`
      console.log('Fetching employees from:', url) // Debug log
      const response = await axios.get(url)
      console.log('Employees response:', response.data) // Debug log
      setEmployees(response.data)
    } catch (error) {
      console.error('Error loading employees:', error)
    }
    setLoadingEmployees(false)
  }

  const fetchScheduleData = async () => {
    setLoadingSchedule(true);
    // Clear existing schedule data
    setScheduleData({
      standardSchedules: [],
      modifiedSchedules: [],
      breaks: []
    });
    
    try {
      const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });
      
      const locQuery = locationId ? `&locationId=${locationId}` : '';
      const response = await axios.get(`/employee/schedulesByDateRange?startDate=${weekStart.getTime()}&endDate=${weekEnd.getTime()}`);

      setScheduleData({
        standardSchedules: employees.map(e => ({
          employeeId: e._id,
          ...e.standardSchedule
        })),
        modifiedSchedules: response.data.modifiedSchedules,
        breaks: response.data.breaks
      });
    } catch (error) {
      console.error('Error fetching schedule data:', error);
      setScheduleData({
        standardSchedules: employees.map(e => ({
          employeeId: e._id,
          ...e.standardSchedule
        })),
        modifiedSchedules: [],
        breaks: []
      });
    }
    setLoadingSchedule(false);
  };

  const handleNextWeek = () => setCurrentWeek(addWeeks(currentWeek, 1))
  const handlePrevWeek = () => setCurrentWeek(subWeeks(currentWeek, 1))
  const handleToday = () => setCurrentWeek(new Date())

  const weekDays = React.useMemo(() => eachDayOfInterval({
    start: startOfWeek(currentWeek, { weekStartsOn: 1 }),
    end: endOfWeek(currentWeek, { weekStartsOn: 1 })
  }), [currentWeek])

  const getScheduleForDay = React.useCallback((employee, date) => {
    if (!employee?._id || !date || !scheduleData) {
      console.log('Missing required data:', { 
        employeeId: employee?._id, 
        date, 
        hasScheduleData: !!scheduleData 
      });
      return null;
    }
    
    // First check for modified schedule
    const modifiedSchedule = scheduleData.modifiedSchedules.find(
      schedule => schedule.employeeId === employee._id && 
        schedule.dateEpoch && 
        isSameDay(new Date(schedule.dateEpoch), date)
    );

    if (modifiedSchedule) {
      return {
        startTime: modifiedSchedule.startTime,
        endTime: modifiedSchedule.endTime,
        isModified: true,
        enabled: modifiedSchedule.enabled
      };
    }

    // If no modified schedule, use standard schedule from employee object
    if (employee.standardSchedule) {
      const dayOfWeek = format(date, 'EEEE'); // get current day in lowercase
      const daySchedule = employee.standardSchedule[dayOfWeek];
      
      if (daySchedule) {
        return {
          startTime: daySchedule.startTime,
          endTime: daySchedule.endTime,
          isModified: false,
          enabled: daySchedule.enabled !== false // if not explicitly false, consider it enabled
        };
      }
    }

    return null;
  }, [scheduleData]);

  const getBreaksForDay = React.useCallback((employee, date) => {
    if (!employee?._id || !date || !scheduleData?.breaks) return [];
    
    return scheduleData.breaks.filter(
      breakItem => breakItem.employeeId === employee._id && 
        breakItem.dateEpoch && 
        isSameDay(new Date(breakItem.dateEpoch), date)
    )
  }, [scheduleData])

  const handleScheduleClick = (employee, date, data) => {



    setSelectedEmployee({...employee, workingStartTime: data.schedule.enabled ? data.schedule.startTime : null, workingEndTime: data.schedule.enabled ? data.schedule.endTime : null, modifiedSchedule: data.isModified ? data.schedule : null})
    setSelectedDate(date)
    setShowHoursModal(true)
  }

  const handleBreakClick = (employee, date, breakData) => {
    setSelectedEmployee(employee)
    setSelectedDate(date)
    setSelectedBreak({...breakData, employee: employee})
    setShowBreakModal(true)
  }

  const handleNextDay = () => setMobileSelectedDate(addDays(mobileSelectedDate, 1))
  const handlePrevDay = () => setMobileSelectedDate(subDays(mobileSelectedDate, 1))

  let loading = loadingEmployees || loadingSchedule

  return (
    <>
      <DefaultMenu />
      <IonPage id="main-content">
        <IonHeader>
          <IonToolbar color="white" style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot="start">
              <IonButton routerLink="/core/employees" routerDirection="back">
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>Employee Schedule</IonTitle>
            <IonButtons slot="end">
              {isMobile ? (
                <>
                  <IonButton onClick={handlePrevDay}>
                    <IonIcon icon={chevronBack} />
                  </IonButton>
                  <IonButton onClick={() => setMobileSelectedDate(new Date())}>
                    <IonIcon icon={today} />
                  </IonButton>
                  <IonButton onClick={handleNextDay}>
                    <IonIcon icon={chevronForward} />
                  </IonButton>
                </>
              ) : (
                <>
                  <IonButton onClick={handlePrevWeek}>
                    <IonIcon icon={chevronBack} />
                  </IonButton>
                  <IonButton onClick={handleToday}>
                    <IonIcon icon={today} />
                  </IonButton>
                  <IonButton onClick={handleNextWeek}>
                    <IonIcon icon={chevronForward} />
                  </IonButton>
                </>
              )}
            </IonButtons>
            {loading && <IonProgressBar type="indeterminate" />}
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div style={{ 
            height: '100%', 
            width: '100%', 
            display: 'flex', 
            flexDirection: 'column' 
          }}>
            {isMobile ? (
              <MobileScheduleView
                employees={employees}
                selectedDate={mobileSelectedDate}
                getScheduleForDay={getScheduleForDay}
                getBreaksForDay={getBreaksForDay}
                handleScheduleClick={handleScheduleClick}
                handleBreakClick={handleBreakClick}
                businessId={businessData?._id}
                loading={loading}
              />
            ) : (
              <div style={{ 
                flex: 1,
                width: '100%',
                height: '100%',
                position: 'relative',
                overflow: 'hidden'
              }}>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflowX: 'auto',
                  overflowY: 'auto'
                }}>
                  <table style={{ 
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0,
                    tableLayout: 'fixed',
                    backgroundColor: 'white'
                  }}>
                    <TableHead weekDays={weekDays} />
                    <tbody>
                      {employees.map(employee => (
                        <tr key={employee._id}>
                          <td
                            style={{
                              minWidth: '150px',
                              width: '150px',
                              backgroundColor: 'white',
                              padding: '16px',
                              position: 'sticky',
                              left: 0,
                              zIndex: 10,
                              borderBottom: '1px solid #e5e7eb',
                              borderRight: '1px solid #e5e7eb',
                              fontWeight: 'bold',
                              boxShadow: '2px 0 4px -2px rgba(0, 0, 0, 0.15)'
                            }}
                          >
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                            }}>
                              <div style={{
                                width: '32px',
                                height: '32px',
                                borderRadius: '16px',
                                overflow: 'hidden',
                                flexShrink: 0,
                                marginTop: '4px'
                              }}>
                                <Avatar employee={employee} businessId={businessData?._id} mini />
                              </div>
                              <div>
                                {employee.firstName} {employee.lastName}
                              </div>
                            </div>
                          </td>
                          {weekDays.map(day => {
                            const schedule = !loading ? getScheduleForDay(employee, day) : null;
                            const breaks = !loading ? getBreaksForDay(employee, day) : [];
                            return (
                              <td
                                key={day.toISOString()}
                                style={{
                                  minWidth: '150px',
                                  width: '150px',
                                  backgroundColor: 'white',
                                  padding: '10px',
                                  borderBottom: '1px solid #e5e7eb',
                                  borderRight: '1px solid #e5e7eb'
                                }}
                              >
                                {loading ? (
                                  <ShimmerCell />
                                ) : (
                                  <ScheduleCell 
                                    data={{
                                      schedule,
                                      breaks,
                                      isModified: schedule?.isModified
                                    }}
                                    employee={employee}
                                    date={day}
                                    onScheduleClick={handleScheduleClick}
                                    onBreakClick={handleBreakClick}
                                    businessId={businessData?._id}
                                    fetchScheduleData={fetchScheduleData}
                                  />
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>

      {showHoursModal && selectedEmployee && selectedDate && (
        <EmployeeHoursModal
          isOpen={showHoursModal}
          onClose={() => {
            setShowHoursModal(false)
            setSelectedEmployee(null)
            setSelectedDate(null)
          }}
          employee={selectedEmployee}
          date={selectedDate}
          refreshAppointments={fetchScheduleData}
        />
      )}

      {showBreakModal && selectedEmployee && selectedDate && selectedBreak && (
        <BreakModal
          isOpen={showBreakModal}
          onClose={() => {
            setShowBreakModal(false)
            setSelectedEmployee(null)
            setSelectedDate(null)
            setSelectedBreak(null)
          }}
          breakData={selectedBreak}
          onBreakUpdate={fetchScheduleData}
        />
      )}
    </>
  )
}

export default SchedulePage 