import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import CreateAccountForm from './CreateAccountForm';
import AccountsList from './AccountsList';
import StandardContainer from '../commonComponents/StandardContainer';
import { IonContent, IonPage, IonSpinner, IonButton } from '@ionic/react';
import { AuthContext } from '../auth';

const CashManagement = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const { userData, selectedLocationData } = useContext(AuthContext);

  const fetchAccounts = async () => {
    if (!userData?._id) return;
    try {
      setLoading(true);
      const response = await axios.get('/cashManagement/accounts');
      setAccounts(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch accounts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [userData?._id, selectedLocationData?._id]);


  const handleAccountCreated = async (data) => {
    try {
      let response = await axios.post('/cashManagement/accounts', data);
      if (response.status === 200) {
        await fetchAccounts();
        setShowCreateForm(false);
      } else {
        setError('Failed to create account');
      }
    } catch (err) {
      setError('Failed to create account');
    }
  };

  const handleAddAccount = () => {
    setShowCreateForm(true);
  };

  let body;

  if (loading) {
    body = (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '40px'
      }}>
        <IonSpinner />
      </div>
    );
  } else if (error) {
    body = (

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px 20px',
        height: '100%',
        textAlign: 'center'
      }}>
        <div style={{
          fontSize: '18px',
          color: '#222222',
          marginBottom: '12px',
          fontWeight: '500'
        }}>
          Unable to load accounts
        </div>
        <div style={{
          fontSize: '14px',
          color: '#717171',
          marginBottom: '24px',
          maxWidth: '300px'
        }}>
          {error}
        </div>
        <IonButton
          onClick={() => fetchAccounts()}
          style={{
            '--background': '#222222',
            '--background-hover': '#000000',
            '--background-activated': '#000000',
            '--border-radius': '8px',
            '--box-shadow': 'none',
            height: '44px',
            fontSize: '16px',
            fontWeight: '500',
            padding: '0 24px'
          }}
        >
          Try Again
        </IonButton>
      </div>
    );
  } else if (!accounts || accounts.length === 0 || showCreateForm) {
    body = (
      <CreateAccountForm
        onSubmit={handleAccountCreated}
        onBack={accounts.length > 0 ? () => setShowCreateForm(false) : undefined}
        isFirstAccount={accounts.length === 0}
      />
    );
  } else {
    body = (
      <div className="cash-management">
          <StandardContainer>
            <div style={{
              padding: '40px 20px 20px',
              maxWidth: '800px',
              margin: '0 auto'
            }}>
              <h1 style={{
                fontSize: '32px',
                fontWeight: '600',
                color: '#222222',
                margin: '0 0 32px 0'
              }}>
                Cash Management Accounts
              </h1>
              <AccountsList
                accounts={accounts}
                onAddAccount={handleAddAccount}
              />
            </div>
          </StandardContainer>
        </div>
    );
  }

  return (
    <IonPage>
      <IonContent>
        {body}
      </IonContent>
    </IonPage>
  );
};

export default CashManagement;
