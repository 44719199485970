import React from 'react'
import { IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonList, IonItem, IonLabel, IonChip } from '@ionic/react'
import { format } from 'date-fns'
import { colors } from '../../theme/colors'

const WalkInAppointmentsModal = ({ isOpen, onClose, appointments }) => {
  const walkInAppointments = appointments.filter(apt => apt.type === 'Walkin')

  const modalStyles = {
    '--height': '90%',
    '--border-radius': '16px',
  }

  const headerStyles = {
    '--background': '#ffffff',
    '--border-style': 'none',
    padding: '16px',
  }

  const titleStyles = {
    fontSize: '24px',
    fontWeight: '600',
    color: '#222222',
  }

  const closeButtonStyles = {
    '--color': '#222222',
    '--padding-end': '8px',
  }

  const listStyles = {
    background: 'transparent',
    padding: '0 16px',
  }

  const itemStyles = {
    '--background': '#ffffff',
    '--border-radius': '12px',
    '--padding-start': '16px',
    '--padding-end': '16px',
    margin: '8px 0',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    border: '1px solid #e8e8e8',
  }

  const statusStyles = {
    Scheduled: {
      backgroundColor: "rgba(213, 217, 235, 0.60)",
      textColor: "#525252",
    },
    Arrived: {
      backgroundColor: "#fff",
      border: "2px dotted #0068DE",
      textColor: "#0068DE",
    },
    "In Progress": {
      backgroundColor: "rgba(194, 222, 255, 0.90)",
      textColor: "#406A8C",
    },
    Complete: {
      backgroundColor: "rgba(169, 239, 220, 0.60)",
      textColor: "#3B8C75",
    },
    Canceled: {
      backgroundColor: "#fff",
      border: "1px dashed #d3d3d3",
      textColor: "#d3d3d3",
    },
    "No Show": {
      backgroundColor: "#fff",
      border: "1px dashed #d3d3d3",
      textColor: "#d3d3d3",
    },
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} style={modalStyles}>
      <IonHeader>
        <IonToolbar style={headerStyles}>
          <IonTitle style={titleStyles}>Walk-in Appointments</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose} style={closeButtonStyles}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList style={listStyles}>
          {walkInAppointments.length === 0 ? (
            <div style={{
              padding: '32px 16px',
              textAlign: 'center',
              color: '#717171',
            }}>
              <div style={{
                width: '48px',
                height: '48px',
                margin: '0 auto 16px',
                backgroundColor: '#f7f7f7',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#717171" strokeWidth="2">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
              </div>
              <p style={{
                fontSize: '16px',
                fontWeight: '500',
                margin: '0',
              }}>No walk-in appointments</p>
            </div>
          ) : (
            walkInAppointments.map((apt) => (
              <IonItem key={apt._id} routerLink={`/core/appointments/${apt._id}`} routerDirection='forward' onClick={onClose} style={itemStyles}>
                <div style={{ padding: '16px 0', width: '100%' }}>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'flex-start', 
                    justifyContent: 'space-between',
                    marginBottom: '16px'
                  }}>
                    <div>
                      <div style={{ 
                        fontSize: '20px',
                        fontWeight: '600',
                        color: '#222222',
                        marginBottom: '4px',
                        letterSpacing: '-0.2px'
                      }}>
                        {apt.customer?.firstName} {apt.customer?.lastName}
                      </div>
                    </div>
                    <IonChip
                      style={{
                        margin: 0,
                        backgroundColor: '#fff7ed',
                        fontWeight: '600',
                        fontSize: '12px',
                        height: '24px',
                        flexShrink: 0,
                        ...statusStyles[apt.status]
                      }}
                    >
                      {apt.status == "Scheduled" ? "Walk-in" : apt.status}
                    </IonChip>
                  </div>
                  <div style={{ color: '#717171', fontSize: '14px', lineHeight: '1.5' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '4px' }}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <circle cx="12" cy="12" r="10"/>
                        <polyline points="12 6 12 12 16 14"/>
                      </svg>
                      {format(new Date(apt.createdDate), 'h:mm a')}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '4px' }}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                        <circle cx="12" cy="7" r="4"/>
                      </svg>
                      {apt.barber?.firstName} {apt.barber?.lastName}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"/>
                      </svg>
                      {apt.services.map(service => service.name).join(', ')}
                    </div>
                  </div>
                </div>
              </IonItem>
            ))
          )}
        </IonList>
      </IonContent>
    </IonModal>
  )
}

export default WalkInAppointmentsModal 