import { IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonPopover, IonProgressBar, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react'
import { addCircle, calendarOutline, list, storefrontOutline } from 'ionicons/icons'
import ExportCustomersToCSV from '../customers/exportToCSV'
import { useState } from 'react'

export default function AddHeader({
  handleAddClick,
  defaultView,
  loading,
  title,
  handleSearchValue,
  searchValue,
  additionalToolbar,
  showExportButton,
  handleView,
}) {
  const [errorMessage, setErrorMessage] = useState('')
  const updateErrorMessage = (message) => {
    setErrorMessage(errorMessage)
  }
  return (
    <IonHeader>
      <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
        <IonButtons slot='start'>
          <IonMenuButton menu='main-content'>
            <IonIcon icon={storefrontOutline}></IonIcon>
          </IonMenuButton>
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        {handleView && (
          <IonButtons slot='end'>
            <IonButton onClick={handleView}>
              <IonIcon slot='icon-only' icon={defaultView == 'list' ? calendarOutline : list} />
            </IonButton>
          </IonButtons>
        )}
        {handleAddClick ? (
          <IonButtons slot='end'>
            <IonButton color='primary' onClick={handleAddClick} className='desktop-button'>
              Create
            </IonButton>
            <IonButton color='primary' onClick={handleAddClick} className='mobile-button'>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>
        ) : null}

        <IonButtons slot='end'>{showExportButton && <ExportCustomersToCSV title={title} updateErrorMessage={updateErrorMessage} />}</IonButtons>
        {additionalToolbar ? null : <>{loading ? <IonProgressBar type='indeterminate' /> : null}</>}
        {errorMessage && 'error Displaying edport error'}
      </IonToolbar>
      {handleSearchValue ? (
        <IonToolbar style={{ background: 'white' }} color={'default'}>
          <IonSearchbar
            color='light'
            placeholder='Search...'
            mode='ios'
            onIonInput={(e) => {
              handleSearchValue(e.detail.value)
            }}
          />
        </IonToolbar>
      ) : null}
      {additionalToolbar ? (
        <IonToolbar style={{ background: 'white' }} color={'default'}>
          {additionalToolbar}
          {additionalToolbar ? <>{loading ? <IonProgressBar type='indeterminate' /> : null}</> : null}
        </IonToolbar>
      ) : null}
    </IonHeader>
  )
}
