import { IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'

const BarChart = ({ data, statusKeys, colors, xAxisCategories, locationId }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: false,
        fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        background: '#ffffff',
      },
      xaxis: {
        categories: [],
        tickAmount: 5,
        tickPlacement: 'on',
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 400,
            colors: '#717171'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          endingShape: 'rounded',
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        width: 0,
        colors: ['transparent'],
      },
      grid: {
        borderColor: '#f7f7f7',
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10
        },
        yaxis: {
          lines: {
            show: true,
            opacity: 0.1
          }
        },
        position: 'back'
      },
      yaxis: {
        title: {
          text: 'Appointments',
          style: {
            fontSize: '14px',
            fontWeight: 500,
            color: '#222222'
          }
        },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 400,
            colors: '#717171'
          }
        }
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      export: {
        show: false,
      },
      tooltip: {
        shared: false,
        style: {
          fontSize: '12px',
          fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        },
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      legend: {
        show: true,
        offsetY: -10,
        fontSize: '13px',
        fontWeight: 400,
        markers: {
          width: 12,
          height: 12,
          radius: 6,
          shape:'circle'
        },
        itemMargin: {
          horizontal: 10,
          vertical: 5
        }
      },
      colors: colors || ['#FF385C', '#E31C5F', '#BD1E59', '#92174D'], // Airbnb-inspired colors
    },
    series: [],
  })

  useEffect(() => {
    if (!data || data.length === 0) {
      return
    }

    const series = statusKeys.map((status) => {
      return {
        name: status,
        data: data.map((datum) => datum[status] || 0),
      }
    })

    const categories = data.map((datum) => datum.date)
    const newChartData = {
      ...chartData,
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories: xAxisCategories ? xAxisCategories : categories,
        },
      },
      series,
    }
    //if we dont get any categories from the parent component then format the labels
    if (!xAxisCategories) {
      newChartData.options.xaxis.labels = {
        show: true,
        formatter: function (value, timestamp, index) {
          const date = new Date(value)
          return date.toLocaleString('default', { month: 'short', day: 'numeric' })
        },
      }
    }

    setChartData(newChartData)
  }, [data])


  if (!data || data.length === 0 || !chartData) {
    return null
  }

  if (chartData?.series?.length === 0) {
    return (
      <StandardCenterCenter>
        <IonSpinner style={{ marginTop: 40, marginBottom: 40 }} name='dots' />
      </StandardCenterCenter>
    )
  }

  return <Chart options={chartData.options} series={chartData.series} type='bar' height={250} style={{ marginBottom: -40, marginLeft:-20, marginRight:-10 }} />
}

export default BarChart

function reformatDate(value) {
  const n = 2
  const date = new Date(value)
  return date.toLocaleString('default', { month: 'short', day: 'numeric' })
}
