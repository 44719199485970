import { IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow, IonIcon, IonMenuButton } from '@ionic/react'
import { useContext } from 'react'
import { globeOutline, storefrontOutline } from 'ionicons/icons' // import the home icon from ionicons
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { fontSizes, fontWeights } from '../theme/typography'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'

export default function Settings() {
  const { businessData } = useContext(AuthContext)

  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content'>
        <IonContent className='regularBackground'>
          <StandardContainer padding={20}>
            <IonMenuButton style={{ display: 'flex', width: 'fit-content' }} color={'dark'} menu='main-content'>
              {' '}
              <IonIcon icon={storefrontOutline}></IonIcon>
            </IonMenuButton>
            <IonGrid>
              <IonRow>
                <IonLabel style={{ fontSize: fontSizes.size35, marginTop: 10, fontWeight: fontWeights.weightBold }}>Settings</IonLabel>
              </IonRow>
              <IonRow style={{ marginTop: '2rem' }}>
                <IonLabel style={{ fontWeight: fontWeights.weightBold }}>Business Details</IonLabel>
              </IonRow>
            </IonGrid>
            <IonList mode='ios' inset={true}>
              <IonItem routerLink={`/business/details/${businessData?._id}`} detail={true}>
                <IonIcon slot='start' icon={storefrontOutline} />
                <IonLabel>{businessData?.name} details</IonLabel>
              </IonItem>
              <IonItem routerLink={`/business/domains/${businessData?._id}`} detail={true}>
                <IonIcon slot='start' icon={globeOutline} />
                <IonLabel>Domains</IonLabel>
              </IonItem>
            </IonList>
          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}
