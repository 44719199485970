import { IonCol, IonGrid, IonItem, IonList, IonRow } from '@ionic/react'
import { colors } from '../../theme/colors'
import ResultItem from './ResultItem'

export default function ResultsList({ appointments, loading, businessId, breaks }) {
  let filteredAppointments = appointments?.filter(
    a =>
      a.status !== 'Canceled' &&
      (a.type !== 'Walkin' || (a.type === 'Walkin' && a.status === 'Complete')),
  )
  let filteredWalkins = appointments?.filter(
    a =>
      a.type == 'Walkin' &&
      (a.status == 'Scheduled' || a.status == 'Arrived' || a.status == 'In Progress'),
  )
  let walkinsWaitingList = appointments?.filter(
    a => a.type == 'Walkin' && (a.status == 'Scheduled' || a.status == 'Arrived'),
  )

  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Walk-ins</h6>
      {!loading && filteredWalkins?.length == 0 ? (
        <p style={{ marginLeft: 20 }}>No Walk-ins Waiting</p>
      ) : !loading ? (
        <div
          style={{
            backgroundColor: colors.orange,
            marginLeft: 20,
            width: 160,
            textAlign: 'center',
            borderRadius: 20,
            padding: 4,
            fontSize: 13,
            fontWeight: '700',
            color: 'white',
          }}
        >
          {walkinsWaitingList?.length} Walk-in{walkinsWaitingList?.length != 1 ? 's' : ''} Waiting
        </div>
      ) : (
        <p>&nbsp;</p>
      )}
      <IonList inset={true} mode='ios'>
        {filteredWalkins?.map(row => (
          <ResultItem row={row} businessId={businessId} key={row?._id} />
        ))}
      </IonList>

      <h6 style={{ color: 'grey', marginLeft: 20 }}>Appointments</h6>
      {!loading && filteredAppointments?.length == 0 ? (
        <p style={{ marginLeft: 20 }}>No Appointments</p>
      ) : null}
      <IonList inset={true} mode='ios'>
        {filteredAppointments?.map(row => (
          <ResultItem row={row} businessId={businessId} key={row?._id} />
        ))}
      </IonList>

      <h6 style={{ color: 'grey', marginLeft: 20 }}>Breaks</h6>
      <IonList inset={true} mode='ios'>
        {breaks?.map(row => (
          <IonItem key={row?._id}>
            <IonGrid style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}>
              <IonRow style={{ padding: 0, margin: 0 }}>
                <IonCol style={{ padding: 0, margin: 0 }}>
                  {row?.employee?.[0].firstName} {row?.employee?.[0].lastName}
                </IonCol>
                <IonCol size='auto' style={{ padding: 0, margin: 0 }}>
                  {row?.startTime} - {row?.endTime}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        ))}
      </IonList>
    </>
  )
}
