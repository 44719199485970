// src/components/EmployeeColumn.jsx
import React, { useState } from 'react';
import EventCard from './EventCard';
import { parseTimeString } from './calendarUtils';

const EmployeeColumn = ({
  employee,
  events,
  scaleFactor,
  calendarStartMinutes,
  calendarEndMinutes,
  closedBeforeHeight,
  closedAfterTop,
  closedAfterHeight,
  statusStyles,
  selectedDate,
  refreshAppointments
}) => {
  const [showHoursModal, setShowHoursModal] = useState(false);
  let employeeWorkingStartMinutes, employeeWorkingEndMinutes;
  if (employee.workingStartTime && employee.workingEndTime) {
    employeeWorkingStartMinutes = parseTimeString(employee.workingStartTime);
    employeeWorkingEndMinutes = parseTimeString(employee.workingEndTime);
  }

  // Calculate total hours for the grid
  const totalHours = (calendarEndMinutes - calendarStartMinutes) / 60;

  return (
    <>
      <div style={{ position: 'relative', height: '100%', borderLeft: '1px solid #f0f0f0' }}>
        {/* Render hourly grid lines */}
        {Array.from({ length: totalHours }, (_, i) => (
          <div
            key={i}
            style={{
              position: 'absolute',
              top: i * 60 * scaleFactor,
              left: 0,
              right: 0,
              borderTop: '1px solid #f0f0f0',
              zIndex: 1,
            }}
          />
        ))}
        {/* Global overlays */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: closedBeforeHeight,
            backgroundColor: 'rgba(220,220,220,0.1)',
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: closedAfterTop,
            left: 0,
            right: 0,
            height: closedAfterHeight,
            backgroundColor: 'rgba(220,220,220,0.1)',
            zIndex: 1,
          }}
        />
        {/* Working hours overlay */}
        {employeeWorkingStartMinutes && employeeWorkingEndMinutes && (
          <>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: (employeeWorkingStartMinutes - calendarStartMinutes) * scaleFactor,
                backgroundColor: 'rgba(180,180,180,0.2)',
                zIndex: 2,
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: (employeeWorkingEndMinutes - calendarStartMinutes) * scaleFactor,
                left: 0,
                right: 0,
                height: (calendarEndMinutes - employeeWorkingEndMinutes) * scaleFactor,
                backgroundColor: 'rgba(180,180,180,0.2)',
                zIndex: 2,
              }}
            />
          </>
        )}
        {events.map((event) => {
          const topOffset = (event.startMinutes - calendarStartMinutes) * scaleFactor;
          const height = event.durationMin * scaleFactor;
          const leftPercent = (event.column / event.totalColumns) * 100;
          const widthPercent = 100 / event.totalColumns;
          return (
            <EventCard
              key={event._id}
              event={event}
              topOffset={topOffset}
              height={height}
              leftPercent={leftPercent}
              widthPercent={widthPercent}
              statusStyles={statusStyles[event.status] || {}}
              onBreakUpdate={refreshAppointments}
            />
          );
        })}
      </div>
    </>
  );
};

export default EmployeeColumn;
