// src/utils/calendarUtils.js

// Parses a time string like "9:30 AM" and returns minutes after midnight.
export const parseTimeString = (timeStr) => {
  if (!timeStr) return 0;
    const parts = timeStr?.split(' ');
    let timePart = parts[0];
    if (!timePart.includes(':')) {
      timePart = `${timePart}:00`;
    }
    const [hoursStr, minutesStr] = timePart.split(':');
    const meridiem = parts[1] || 'AM';
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    if (meridiem.toUpperCase() === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (meridiem.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  };
  
  // Given an array of events, compute overlapping positions (assigns each event a "column" and "totalColumns").
  export const assignOverlapPositions = (events) => {
    const eventsCopy = events.map(e => ({ ...e }));
    eventsCopy.sort((a, b) => a.startMinutes - b.startMinutes);
    const clusters = [];
    let currentCluster = [];
    let currentClusterEnd = 0;
    eventsCopy.forEach(event => {
      if (currentCluster.length === 0) {
        currentCluster.push(event);
        currentClusterEnd = event.endMinutes;
      } else {
        if (event.startMinutes < currentClusterEnd) {
          currentCluster.push(event);
          currentClusterEnd = Math.max(currentClusterEnd, event.endMinutes);
        } else {
          clusters.push(currentCluster);
          currentCluster = [event];
          currentClusterEnd = event.endMinutes;
        }
      }
    });
    if (currentCluster.length > 0) clusters.push(currentCluster);
    clusters.forEach(cluster => {
      const columns = [];
      cluster.forEach(event => {
        let placed = false;
        for (let i = 0; i < columns.length; i++) {
          const lastEventInColumn = columns[i][columns[i].length - 1];
          if (event.startMinutes >= lastEventInColumn.endMinutes) {
            columns[i].push(event);
            event.column = i;
            placed = true;
            break;
          }
        }
        if (!placed) {
          columns.push([event]);
          event.column = columns.length - 1;
        }
      });
      const totalColumns = columns.length;
      cluster.forEach(event => {
        event.totalColumns = totalColumns;
      });
    });
    return eventsCopy;
  };
  
  // For a given employee, merge their appointments and breaks into one array.
  export const getEmployeeEvents = (employeeId, appointments, breaks) => {
    const employeeAppointments = appointments
      .filter(appt => appt.barber && appt.barber._id === employeeId && appt.startTimeText && appt.endTimeText)
      .map(appt => {
        const startMinutes = parseTimeString(appt.startTimeText);
        return {
          ...appt,
          startMinutes,
          endMinutes: startMinutes + appt.durationMin,
          isBreak: false,
        };
      });
    const employeeBreaks = breaks
      .filter(brk => brk.employeeId === employeeId)
      .map(brk => {
        const startMinutes = parseTimeString(brk.startTime);
        const endMinutes = parseTimeString(brk.endTime);
        return {
          _id: brk._id,
          isBreak: true,
          startTimeText: brk.startTime,
          endTimeText: brk.endTime,
          durationMin: endMinutes - startMinutes,
          startMinutes,
          endMinutes,
          employee: brk?.employee?.[0]
        };
      });
    return [...employeeAppointments, ...employeeBreaks];
  };
  