import { colors } from '../../theme/colors';
import { fontWeights } from '../../theme/typography';

export const filterStyles = {
    container: {
        padding: '0.5rem 0',
        borderBottom: `1px solid ${colors.black10}`,
    },
    header: {
        fontSize: '0.875rem',
        color: colors.black90,
        fontWeight: fontWeights.weightBold,
        marginBottom: '1rem',
        textTransform: 'none',
        letterSpacing: 'normal'
    },
    list: {
        padding: '0',
    },
    item: {
        '--background': 'transparent',
        '--border-radius': '8px',
        marginBottom: '0.5rem',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            '--background': colors.black5
        }
    },
    checkbox: {
        '--border-radius': '50%',
        '--size': '1.25rem',
        '--checkbox-background-checked': colors.primary,
        '--border-color': colors.black20,
        '--border-color-checked': colors.primary,
        '--checkmark-color': 'white',
        '--border-width': '1px'
    },
    label: {
        fontSize: '0.875rem',
        color: colors.black80,
        marginLeft: '0.75rem'
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.75rem'
    }
}; 