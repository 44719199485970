import { buildOutline, storefront, storefrontOutline } from 'ionicons/icons'
import StandardContainer from '../../commonComponents/StandardContainer'
import { IonCard, IonIcon } from '@ionic/react'

export default function StoreClosedCard() {
  return (
    <StandardContainer>
      <IonCard
        style={{
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f7f7f7',
          borderRadius: '16px',
          border: '1px solid #e8e8e8',
          boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
          margin: '1rem',
          minHeight: '200px',
        }}
      >
        <div
          style={{
            width: '48px',
            height: '48px',
            backgroundColor: '#ebebeb',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <IonIcon icon={storefrontOutline} style={{fontSize: 24}}/>
        </div>
        <h2 
          style={{ 
            color: '#222222',
            fontSize: '1.5rem',
            fontWeight: '600',
            margin: '0 0 0.5rem 0',
          }}
        >
          Closed Today
        </h2>
        <p 
          style={{ 
            color: '#717171',
            fontSize: '1rem',
            textAlign: 'center',
            margin: 0,
            maxWidth: '280px',
            lineHeight: '1.5',
          }}
        >
          This location is not open for appointments today. If you want this changed to More Menu -&gt; Store Settings -&gt;Schedule to change the store hours.
        </p>
      </IonCard>
    </StandardContainer>
  )
}
