import { useContext, useEffect, useState } from 'react'
import { addHours, format, startOfDay } from 'date-fns'
import { AuthContext } from '../../auth'
import { setTimeFromDateStr } from './Utils'
import { getWeekday } from '../../utils'
import { IonButton, IonCheckbox, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRow, IonToast } from '@ionic/react'
import CalendarHeader from './header'
import StoreClosedCard from './StoreClosedCard'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import VerticalEvent from './VerticalEvent'
import HorizontalEvent from './HorizontalEvent'
import AddBreak from './AddBreak'
import ResizableModal from '../NewCalendarView/ResizableModal'
import { calculateNewEventStyles, organizeAppointmentsByHour, organizeEventsByName } from '../NewCalendarView/helpers'
import { styles } from '../NewCalendarView/styles'
import WalkinAppointments from '../NewCalendarView/WalkinAppointments'
import Breaks from './Breaks'
import EmployeeFreeTime from '../NewCalendarView/EmployeeFreeTime'
import { colors } from '../../theme/colors'
import Avatar from '../../commonComponents/avatar'
import CommonHeader from '../../commonComponents/CommonHeader'
import moment from 'moment-timezone'
import axios from 'axios'
import { fontWeights } from '../../theme/typography'
import { arrowBackOutline, cafeOutline, calendarOutline, footstepsOutline, resizeOutline } from 'ionicons/icons'
import StandardContainer from '../../commonComponents/StandardContainer'
import StepController from '../../Scheduler/stepController'

import { useHistory } from 'react-router'
import ResourceCalendarDay from '../AI_Calendar'
import TypesFilter from '../components/TypesFilter'
import StylistsFilter from '../components/StylistsFilter'

const CalendarDayView = ({
  getBreaks,
  employees,
  selectedStylists,
  selectedTypes,
  refreshAppointments,
  breaks,
  appointments,
  selectedDate,
  handleView,
  loading,
  setSelectedDate,
  filteredBarbers,
  handleStylistChange,
  handleTypeChange,
  setDefaultView,
  defaultView,
  isLoading,
}) => {

  const formatDateToISO = (date) => {
    const pad = (n) => (n < 10 ? '0' + n : n)
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
  const [isDesktop, setIsDesktop] = useState(false)
  const [locationSchedule, setLocationSchedule] = useState(false)
  const { locationData, locationId } = useContext(AuthContext)
  const [personEvents, setPersonEvents] = useState({})
  const [walkinsList, setFilteredWalkins] = useState([])
  const [allDayEvents, setAllDayEvents] = useState([])
  const [hours, setHours] = useState([])
  const [expandedColumn, setExpandedColumn] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(false)
  const [locationStartTime, setLocationStartTime] = useState(false)
  const [regularAppointments, setAppointments] = useState([])
  const [startDate, setStartDate] = useState(moment(selectedDate))
  const [view, setView] = useState('week') // State to toggle between week and month view
  const [date, setDate] = useState(formatDateToISO(new Date(selectedDate)))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBreakModalOpen, setIsBreakModalOpen] = useState(false)
  const { businessData } = useContext(AuthContext)
  const [isWalkin, setIsWalkin] = useState(false)
  const startOfWeek = moment(selectedDate).startOf('week') // Start of the current selected week
  const [apptModalOpen, setApptModalOpen] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })
  const history = useHistory()
  const handleExpandClick = (person) => {
    setExpandedColumn(expandedColumn === person ? null : person)
  }

  const handlePrevWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() - 7)
    setSelectedDate(newSelectedDate)
  }

  const handleNextWeek = (date) => {
    const newSelectedDate = new Date(date)
    newSelectedDate.setDate(newSelectedDate.getDate() + 7)
    setSelectedDate(newSelectedDate)
  }

  useEffect(() => {
    setExpandedColumn(null)
  }, [selectedDate])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768) // Example breakpoint, adjust as needed
    }
    // Initial check
    handleResize()
    // Event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (locationId) {
      const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

      setLocationSchedule(selectedLocationData.standardSchedule)
      let weekday = getWeekday(selectedDate)
      let standardScheduleForDay = selectedLocationData?.standardSchedule?.[weekday]
      let startTime = standardScheduleForDay?.startTime

      if (startTime) {
        startTime = setTimeFromDateStr(startTime)
        setLocationStartTime(startTime)
      }
    }
  }, [locationId])

  useEffect(() => {
    if (locationId) {
      let weekday = getWeekday(selectedDate)
      const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)

      let standardScheduleForDay = selectedLocationData?.standardSchedule[weekday]

      const startTime = standardScheduleForDay.startTime
      const endTime = standardScheduleForDay.endTime
      let appointmentStart
      let appointmentEnd
      if (startTime) {
        appointmentStart = setTimeFromDateStr(startTime, selectedDate)
        appointmentEnd = setTimeFromDateStr(endTime, selectedDate)
      }

      const startHour = appointmentStart
      const endHour = addHours(startOfDay(selectedDate), 24)
      let hours = []
      let currentHour = startHour

      while (currentHour < endHour) {
        hours.push(currentHour)
        currentHour = addHours(currentHour, 1)
      }

      setHours(hours)

      const regularAppointments = appointments.filter((item, index) => item.startTimeText && item.endTimeText)
      setAppointments(regularAppointments)

      let allDayEvents = {}
      let regularEvents = {}

      for (let a of appointments) {
        if (a?.status !== 'Canceled') {
          let barberName = `${a?.barber?.firstName} ${a?.barber?.lastName}`
          if (a?.barber) {
            if (!allDayEvents[barberName]) {
              allDayEvents[barberName] = []
              regularEvents[barberName] = []
            }
            let dataItem = {
              title: a?.services?.map((service) => service?.name).join(', '),
              _id: a?._id,
              ...a,
            }
            if (a?.barber?._id) {
              if (!a.endTime) {
                dataItem.allDay = true
                dataItem.start = new Date(selectedDate)
                dataItem.end = new Date(selectedDate)
                allDayEvents[barberName].push(dataItem)
              } else {
                dataItem.start = setTimeFromDateStr(a?.startTimeText)
                dataItem.end = setTimeFromDateStr(a?.endTimeText)
                regularEvents[barberName].push(dataItem)
              }
            }
          }
        }
      }

      setAllDayEvents(allDayEvents)
      let filteredWalkins = appointments?.filter((a) => a.type === 'Walkin' && !a?.barber?._id)
      setFilteredWalkins(filteredWalkins)
      setPersonEvents(regularEvents)
    }
  }, [appointments])

  const isStoreClosed = () => {
    const dayOfWeek = selectedDate.getDay()
    const dayOfWeekString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek]
    const todaySchedule = locationSchedule?.[dayOfWeekString]

    return !todaySchedule?.enabled
  }

  const getLocationHours = () => {
    const dayOfWeek = selectedDate.getDay()
    const dayOfWeekString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek]
    const todaySchedule = locationSchedule?.[dayOfWeekString]
    return {
      openTime: todaySchedule?.startTime,
      closeTime: todaySchedule?.endTime,
    }
  }

  const eventData = (event, overlappingEventsCount, person) => {
    if (event.isBreak) {
      return <BreakEvent event={event} />
    }
    if (overlappingEventsCount > 1) {
      return <VerticalEvent event={event} />
    }
    if (event.durationMin < 30 || (expandedColumn == person && event.durationMin < 30)) {
      return <HorizontalEvent expandedColumn={expandedColumn} person={person} event={event} overlappingEventsCount={overlappingEventsCount} />
    } else {
      return <VerticalEvent event={event} />
    }
  }
  const handleSelectedEvent = (event) => {
    history.push(`/core/appointments/${event._id}`)
    // setSelectedEvent(event)
    // setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
    refreshAppointments()
  }
  const renderEvent = (event, person, index) => {
    const overlappingEvents = personEvents[person].filter(
      (otherEvent) =>
        otherEvent._id !== event._id && new Date(otherEvent.startTime) < new Date(event.endTime) && new Date(otherEvent.end) > new Date(event.start)
    )

    if (event.isBreak) {
      event.status = 'Break'
    }
    return (
      <div onClick={() => handleSelectedEvent(event)} style={{ color: 'inherit', width: '100%', height: '100%' }}>
        {eventData(event, overlappingEvents.length, person)}
      </div>
    )
  }
  function assignColumns(events) {
    let rows = []

    // Group events into rows based on overlap
    events.forEach((event) => {
      let placed = false

      for (let row of rows) {
        if (row.some((existingEvent) => isOverlapping(existingEvent, event))) {
          row.push(event)
          placed = true
          break
        }
      }
      if (!placed) {
        rows.push([event])
      }
    })

    // Calculate the width and left position for each event
    rows = rows.map((row) => {
      const totalEvents = row.length
      const widthPercentage = 100 / totalEvents

      return row.map((event, index) => ({
        ...event,
        width: widthPercentage,
        left: widthPercentage * index,
      }))
    })

    return rows
  }

  const isOverlapping = (event1, event2) => {
    return event1.startTime < event2.endTime && event1.endTime > event2.startTime
  }

  function calculateFreeTime(day, employeeSchedule) {
    let freeTimes = []
    if (!locationSchedule?.[day]?.enabled || !employeeSchedule?.[day]?.enabled) {
      return null // If either schedule is not enabled, no free time calculation is needed
    }

    const locStart = locationSchedule[day].startTime
    const locEnd = locationSchedule[day].endTime

    const empStart = employeeSchedule[day].startTime
    const empEnd = employeeSchedule[day].endTime

    const freeTimeBefore = {
      start: locStart,
      end: empStart,
    }
    const freeTimeAfter = {
      start: empEnd,
      end: '11:59 PM',
    }
    freeTimes.push(freeTimeBefore)
    freeTimes.push(freeTimeAfter)

    return freeTimes
  }

  const handleSaveBreak = async (data) => {
    console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
    const businessId = businessData?._id
    const breakInput = {
      ...{ locationId, businessId },
      ...data,
      repeat: data.repeat,
    }

    try {
      await axios.post(`/employee/break`, breakInput)
      setToast({ isOpen: true, message: 'Break saved successfully!', color: 'success' })
      getBreaks()
      closeModal()
      closeBreakModal()
      // Show success toast message
    } catch (error) {
      // Show error toast message
      setToast({ isOpen: true, message: 'Sorry, there was an issue saving. Try again later.', color: 'danger' })
    }
  }

  const handleMonthDateChange = (date) => {
    setSelectedDate(new Date(date))
    setDate(date)
    setStartDate(moment(date))
    setView('week')
  }

  const openEventsModal = () => {
    setIsModalOpen(true)
  }
  const closeEventsModal = () => {
    setIsWalkin(false)
    setIsModalOpen(false)
  }

  const openBreakModal = () => {
    setIsBreakModalOpen(true)
  }

  const closeBreakModal = () => {
    setIsWalkin(false)
    setIsBreakModalOpen(false)
  }

  useEffect(() => {
    setStartDate(moment(selectedDate))
  }, [selectedDate])

  return (
    <>
      <IonContent className='desktop-view regularBackground'>
        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          color={toast.color}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          duration={1000}
          position='top'
        />


        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          backgroundColor: '#f7f7f7'
        }}>
          {/* Sidebar */}
          <div style={{
            width: '16rem',
            borderRight: '1px solid #e8e8e8',
            backgroundColor: '#ffffff',
            overflow: 'auto',
            flexShrink: 0
          }}>
            <div className=''>
              <div className='month-dates-container' style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '1rem'
              }}>
                <IonDatetime
                  mode='ios'
                  presentation='day'
                  value={date}
                  onIonChange={(e) => {
                    handleMonthDateChange(e.detail.value)
                  }}
                  style={{
                    '--ion-font-size': '10px',
                    color: 'black',
                    background: 'white',
                    minHeight: '20rem',
                    borderRadius: '12px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
                  }}
                />
              </div>

              {/* Filters Section */}
              <div style={{ padding: '0 1rem' }}>
                <StylistsFilter 
                   filteredBarbers={filteredBarbers}
                   selectedStylists={selectedStylists}
                   handleStylistChange={handleStylistChange}
                   businessId={businessData?._id}
                />

                <TypesFilter selectedTypes={selectedTypes} handleTypeChange={handleTypeChange} />
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div style={{
            flex: 1,
            overflow: 'auto',
            backgroundColor: '#ffffff',
          }}>
            <ResourceCalendarDay
              selectedDate={selectedDate}
              employees={filteredBarbers}
              breaks={breaks}
              appointments={appointments}
              locationHours={getLocationHours()}
              isLoading={isLoading}
              isStoreClosed={isStoreClosed()}
              refreshAppointments={refreshAppointments}
            />
          </div>
        </div>
      </IonContent>
      <IonContent className='mobile-view regularBackground'>
        <CalendarHeader
          setDefaultView={setDefaultView}
          defaultView={defaultView}
          displayWeeks={true}
          displayCalendar={true}
          getBreaks={getBreaks}
          employees={filteredBarbers}
          selectedDate={selectedDate}
          handlePrevWeek={handlePrevWeek}
          loading={loading}
          handleNextWeek={handleNextWeek}
          setSelectedDate={setSelectedDate}
          refreshAppointments={refreshAppointments}
          appointments={appointments}
        />

        {/* Render store closed message if the store is closed on the selected day */}
        {/* {renderAppointmentsTable()} */}
        <ResourceCalendarDay
           selectedDate={selectedDate}
           employees={filteredBarbers}
           breaks={breaks}
           appointments={appointments}
           locationHours={getLocationHours()}
           isLoading={isLoading}
           isStoreClosed={isStoreClosed()}
           refreshAppointments={refreshAppointments}
           filteredBarbers={filteredBarbers}
           selectedStylists={selectedStylists}
           handleStylistChange={handleStylistChange}
           businessId={businessData?._id}
        />
        <ResizableModal closeModal={closeModal} selectedEvent={selectedEvent} showModal={showModal} setShowModal={setShowModal} />
      </IonContent>
      <IonModal isOpen={isModalOpen} onDidDismiss={closeEventsModal}>
        <CommonHeader title={'Select Event'} closeButton={true} closeModal={closeEventsModal} />
        <IonContent>
          <IonList>
            <IonItem
              detail={true}
              button
              onClick={() => {
                setApptModalOpen(true)
              }}
            >
              <IonIcon slot='start' icon={calendarOutline} />
              <IonLabel>Appointment</IonLabel>
            </IonItem>
            <IonItem
              detail={true}
              button
              onClick={() => {
                setApptModalOpen(true)
                setIsWalkin(true)
              }}
            >
              <IonIcon slot='start' icon={footstepsOutline} />
              <IonLabel>Walk-in</IonLabel>
            </IonItem>
            <IonItem detail={true} button onClick={openBreakModal}>
              <IonIcon slot='start' icon={cafeOutline} />
              <IonLabel>Break</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
      <IonModal isOpen={apptModalOpen}>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <StepController
              isWalkin={isWalkin}
              getAppointments={() => {
                refreshAppointments()
                closeModal()
              }}
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              choosecustomer={true}
              isOpen={apptModalOpen}
              closeModal={() => {
                setApptModalOpen(false)
              }}
              setSubscriptionReferrer={() => { }}
            />
          </StandardContainer>
        </IonContent>
      </IonModal>
      <AddBreak selectedDate={selectedDate} handleSave={handleSaveBreak} employees={filteredBarbers} open={isBreakModalOpen} onClose={closeBreakModal} />
    </>
  )
}

const BreakEvent = ({ event }) => {
  const timeDifference = event.end.getTime() - event.start.getTime()
  const minutesDifference = Math.floor(timeDifference / (1000 * 60))

  return (
    <IonRow className='break-event-row'>
      <div className='label-container'>
        <IonLabel className='break-event-label'>Break</IonLabel>
        <IonLabel className='break-event-info'>{`(${event.startTime}-${event.endTime}) (${minutesDifference} min)`}</IonLabel>
      </div>
    </IonRow>
  )
}
export default CalendarDayView
