// src/components/EventCard.jsx
import React, { useState } from 'react';
import { IonCard, IonCardContent, IonIcon, IonRouterLink } from '@ionic/react';
import { timeOutline, starOutline } from 'ionicons/icons';
import BreakModal from './BreakModal';
import styled from '@emotion/styled';

const BreakCardContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #6938EF;
  font-weight: bold;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    opacity: 0.9;
  }

  &:active {
    transform: translateY(0px);
    filter: brightness(0.95);
  }
`;

const EventCardContainer = styled.div`
  position: absolute;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    opacity: 0.9;
  }

  &:active {
    transform: translateY(0px);
    filter: brightness(0.95);
  }
`;

const EventCard = ({ event, topOffset, height, leftPercent, widthPercent, statusStyles, onBreakUpdate }) => {
  const [showBreakModal, setShowBreakModal] = useState(false);

  const isRequestedBarber = event.requestedBarber && 
    event.requestedBarber._id === event.barber._id;

  if (event.isBreak) {
    return (
      <>
        <BreakCardContainer
          onClick={() => setShowBreakModal(true)}
          style={{
            top: topOffset,
            left: `${leftPercent}%`,
            width: `${widthPercent - 1}%`,
            height: height - 2,
            background: `repeating-linear-gradient(
              -45deg,
              rgba(217, 214, 254, 0.60),
              rgba(217, 214, 254, 0.60) 10px,
              rgba(255, 255, 255, 1) 10px,
              rgba(255, 255, 255, 1) 12px
            )`,
            zIndex: 3,
          }}
        >
          {`Break (${event.startTimeText} - ${event.endTimeText})`}
        </BreakCardContainer>

        <BreakModal 
          isOpen={showBreakModal}
          onClose={() => setShowBreakModal(false)}
          breakData={event}
          onBreakUpdate={onBreakUpdate}
        />
      </>
    );
  } else {
    if (event.durationMin <= 45) {
      // One-line layout for short events.
      return (
        <IonRouterLink
          routerLink={`/core/appointments/${event._id}`}
          routerDirection='forward'
        >
          <EventCardContainer
            style={{
              top: topOffset,
              left: `${leftPercent}%`,
              width: `${widthPercent - 1}%`,
              height: height - 2,
              margin: 0,
              marginLeft: 1,
              marginRight: 1,
              backgroundColor: statusStyles.backgroundColor,
              border: statusStyles.border || "none",
              zIndex: 3,
              color: statusStyles.textColor
            }}
          >
            <div
              style={{
                padding: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '11px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingTop: event.durationMin > 20 ? '4px' : '0px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span style={{ fontWeight: 'bold' }}>{`${event.customer.firstName} ${event.customer.lastName}`}</span>&nbsp;{` | ${event.services && event.services[0]?.name}`}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
                <IonIcon icon={timeOutline} style={{ marginRight: '4px' }} />
                <span>{event.durationMin}m</span>
              </div>
            </div>
            {isRequestedBarber && (
              <IonIcon
                icon={starOutline}
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: '2px',
                  fontSize: '10px',
                  color: statusStyles.textColor
                }}
              />
            )}
          </EventCardContainer>
        </IonRouterLink>
      );
    } else {
      // Two-line layout for longer events.
      return (
        <IonRouterLink
          routerLink={`/core/appointments/${event._id}`}
          routerDirection='forward'
        >
          <EventCardContainer
            style={{
              top: topOffset,
              left: `${leftPercent}%`,
              width: `${widthPercent - 1}%`,
              height: height - 2,
              margin: 0,
              marginLeft: 1,
              marginRight: 1,
              backgroundColor: statusStyles.backgroundColor,
              border: statusStyles.border || "none",
              zIndex: 3,
              color: statusStyles.textColor
            }}
          >
            <div
              style={{
                padding: '4px',
                fontSize: '11px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>
                  {`${event.customer.firstName} ${event.customer.lastName}`}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 10 }}>
                  <IonIcon icon={timeOutline} style={{ marginRight: '4px' }} />
                  <span>{event.durationMin}m</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>{event.services && event.services[0]?.name}</div>
              </div>
            </div>
            {isRequestedBarber && (
              <IonIcon
                icon={starOutline}
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: '2px',
                  fontSize: '10px',
                  color: statusStyles.textColor
                }}
              />
            )}
          </EventCardContainer>
        </IonRouterLink>
      );
    }
  }
};

export default EventCard;
