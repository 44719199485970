import React, { useState, useEffect } from 'react'
import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonSpinner,
    IonText,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonChip
} from '@ionic/react'
import { star, gridOutline, listOutline, caretUp, caretDown } from 'ionicons/icons'
import axios from 'axios'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { colors } from '../theme/colors'

const VIEW_PREFERENCE_KEY = 'requestedProfessionalsView'

const RequestedProfessionals = () => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])
    const [dateRange, setDateRange] = useState('30')
    const [viewType, setViewType] = useState(() => {
        return localStorage.getItem(VIEW_PREFERENCE_KEY) || 'cards'
    })
    const [sortConfig, setSortConfig] = useState({
        key: 'requestPercentage',
        direction: 'desc'
    })

    useEffect(() => {
        fetchData()
    }, [dateRange])

    useEffect(() => {
        localStorage.setItem(VIEW_PREFERENCE_KEY, viewType)
    }, [viewType])

    const fetchData = async () => {
        setLoading(true)
        setError(null)
        try {
            const endDate = new Date()
            const startDate = new Date()
            startDate.setDate(startDate.getDate() - parseInt(dateRange))
            const formattedStartDate = startDate.toISOString().split('T')[0]
            const formattedEndDate = endDate.toISOString().split('T')[0]

            const response = await axios.get(`/appointment_v2/stylistRequestStats?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
            setData(response.data)
        } catch (err) {
            setError('Failed to load report data')
        } finally {
            setLoading(false)
        }
    }

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc'
        }))
    }

    const getSortedData = () => {
        const sortedData = [...data]
        sortedData.sort((a, b) => {
            let aValue = a[sortConfig.key]
            let bValue = b[sortConfig.key]
            
            if (sortConfig.key === 'barberName') {
                return sortConfig.direction === 'asc' 
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue)
            }
            
            return sortConfig.direction === 'asc' 
                ? aValue - bValue 
                : bValue - aValue
        })
        return sortedData
    }

    const renderSortIcon = (key) => {
        if (sortConfig.key !== key) return null
        return (
            <IonIcon 
                icon={sortConfig.direction === 'asc' ? caretUp : caretDown}
                style={styles.sortIcon}
            />
        )
    }

    const renderTableHeader = (key, label) => (
        <th 
            style={{
                ...styles.tableHeader,
                cursor: 'pointer',
                userSelect: 'none'
            }}
            onClick={() => handleSort(key)}
        >
            <div style={styles.tableHeaderContent}>
                {label}
                {renderSortIcon(key)}
            </div>
        </th>
    )

    const renderCardView = () => (
        <div style={styles.grid}>
            {data.map((professional) => (
                <div key={professional._id} style={styles.card}>
                    <div style={styles.cardContent}>
                        <div style={styles.nameRow}>
                            <h2 style={styles.name}>{professional.barberName}</h2>
                            <div style={styles.percentage}>
                                <IonIcon icon={star} style={styles.starIcon} />
                                <span>{professional.requestPercentage?.toFixed(1)}%</span>
                            </div>
                        </div>
                        <div style={styles.statsRow}>
                            <div style={styles.stat}>
                                <span style={styles.statLabel}>Requested</span>
                                <span style={styles.statValue}>{professional.requestedAppointments}</span>
                            </div>
                            <div style={styles.stat}>
                                <span style={styles.statLabel}>Total</span>
                                <span style={styles.statValue}>{professional.totalAppointments}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

    const renderTableView = () => (
        <div style={styles.tableContainer}>
            <table style={styles.table}>
                <thead>
                    <tr>
                        {renderTableHeader('barberName', 'Professional')}
                        {renderTableHeader('requestPercentage', 'Request Rate')}
                        {renderTableHeader('requestedAppointments', 'Requested')}
                        {renderTableHeader('totalAppointments', 'Total')}
                    </tr>
                </thead>
                <tbody>
                    {getSortedData().map((professional) => (
                        <tr key={professional._id} style={styles.tableRow}>
                            <td style={styles.tableCell}><strong>{professional.barberName}</strong></td>
                            <td style={styles.tableCell}>
                                <span style={{color: colors.primaryBlue}}>
                                    {professional.requestPercentage?.toFixed(1)}%
                                </span>
                            </td>
                            <td style={styles.tableCell}>{professional.requestedAppointments}</td>
                            <td style={styles.tableCell}>{professional.totalAppointments}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

    return (
        <IonPage>
            <CommonHeader
                title="Requested Professionals"
                backIcon={true}
                style={{
                    '--background': 'white',
                    '--border-style': 'none',
                    '--padding-top': '8px',
                    '--padding-bottom': '8px',
                    '--color': '#222222'
                }}
                backButtonStyle={{
                    '--color': '#222222',
                    '--padding-start': '16px'
                }}
            />
            <IonContent>
                <StandardContainer>
                    <div style={styles.header}>
                        <div style={styles.headerTop}>
                            <div>
                                <h1 style={styles.title}>Professional Request Stats</h1>
                                <p style={styles.subtitle}>See which professionals are most requested by clients</p>
                            </div>
                            <div style={styles.viewToggleContainer}>
                                <button
                                    onClick={() => setViewType('cards')}
                                    style={{
                                        ...styles.viewToggleButton,
                                        ...(viewType === 'cards' ? styles.viewToggleButtonActive : {})
                                    }}
                                >
                                    <IonIcon icon={gridOutline} />
                                </button>
                                <button
                                    onClick={() => setViewType('table')}
                                    style={{
                                        ...styles.viewToggleButton,
                                        ...(viewType === 'table' ? styles.viewToggleButtonActive : {})
                                    }}
                                >
                                    <IonIcon icon={listOutline} />
                                </button>
                            </div>
                        </div>
                        <IonSelect
                            value={dateRange}
                            onIonChange={e => setDateRange(e.detail.value)}
                            interface="popover"
                            style={styles.select}
                        >
                            <IonSelectOption value="7">Last 7 Days</IonSelectOption>
                            <IonSelectOption value="30">Last 30 Days</IonSelectOption>
                            <IonSelectOption value="90">Last 90 Days</IonSelectOption>
                            <IonSelectOption value="365">Last Year</IonSelectOption>
                        </IonSelect>
                    </div>

                    {loading ? (
                        <div style={styles.centered}>
                            <IonSpinner />
                        </div>
                    ) : error ? (
                        <div style={styles.centered}>
                            <IonText color="danger">{error}</IonText>
                        </div>
                    ) : data.length === 0 ? (
                        <div style={styles.centered}>
                            <IonText color="medium">No data available for this time period</IonText>
                        </div>
                    ) : viewType === 'cards' ? renderCardView() : renderTableView()}
                </StandardContainer>
            </IonContent>
        </IonPage>
    )
}

const styles = {
    header: {
        padding: '24px 24px 16px 24px',
        borderBottom: '1px solid #ebebeb',
        marginTop: '20px'
    },
    title: {
        fontSize: '26px',
        fontWeight: '600',
        color: '#222222',
        margin: '0 0 8px 0',
        lineHeight: '30px'
    },
    subtitle: {
        fontSize: '16px',
        color: '#717171',
        margin: '0 0 16px 0',
        lineHeight: '20px'
    },
    select: {
        '--padding-start': '16px',
        '--padding-end': '16px',
        '--padding-top': '8px',
        '--padding-bottom': '8px',
        '--border-radius': '8px',
        '--border-color': '#dddddd',
        '--background': 'white',
        fontSize: '14px',
        fontWeight: '500'
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '48px',
        textAlign: 'center'
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '24px',
        padding: '24px'
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #ebebeb',
        transition: 'box-shadow 0.2s ease, transform 0.2s ease',
        cursor: 'pointer',
        ':hover': {
            boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
            transform: 'translateY(-2px)'
        }
    },
    cardContent: {
        padding: '24px'
    },
    nameRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px'
    },
    name: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#222222',
        margin: 0
    },
    percentage: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        fontSize: '18px',
        fontWeight: '600',
        color: 'var(--ion-color-primary)'
    },
    starIcon: {
        color: 'var(--ion-color-primary)',
        fontSize: '20px'
    },
    statsRow: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '12px'
    },
    stat: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    statLabel: {
        fontSize: '12px',
        color: '#717171',
        textTransform: 'uppercase',
        letterSpacing: '0.5px'
    },
    statValue: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#222222'
    },
    headerTop: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '16px'
    },
    viewToggleContainer: {
        display: 'flex',
        gap: '8px'
    },
    viewToggleButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '36px',
        height: '36px',
        border: '1px solid #dddddd',
        borderRadius: '8px',
        backgroundColor: 'white',
        color: '#717171',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        padding: 0
    },
    viewToggleButtonActive: {
        backgroundColor: colors.primaryBlue,
        borderColor: colors.primaryBlue,
        color: 'white'
    },
    tableContainer: {
        padding: '24px',
        overflowX: 'auto'
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: 'white',
        borderRadius: '12px',
        overflow: 'hidden'
    },
    tableHeader: {
        padding: '16px',
        textAlign: 'left',
        backgroundColor: '#f7f7f7',
        color: '#717171',
        fontWeight: '600',
        fontSize: '12px',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        borderBottom: '1px solid #ebebeb'
    },
    tableRow: {
        borderBottom: '1px solid #ebebeb',
        ':last-child': {
            borderBottom: 'none'
        }
    },
    tableCell: {
        padding: '16px',
        color: '#222222',
        fontSize: '14px'
    },
    tableHeaderContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    sortIcon: {
        fontSize: '14px',
        color: colors.primaryBlue
    }
}

export default RequestedProfessionals 