import React from 'react';
import {
    IonList,
    IonItem,
    IonLabel,
    IonChip,
    IonIcon,
    IonButton,
} from '@ionic/react';
import { walletOutline, chevronForward, addOutline } from 'ionicons/icons';
import { AccessControl } from '../AccessControl';




const AccountsList = ({ accounts, onAddAccount }) => {
    return (
        <div style={{
            maxWidth: '800px',
            margin: '0 auto',
            //   padding: '0 20px' 
        }}>
            <IonList style={{ background: 'transparent' }}>
                {accounts.map(account => (
                    <AccessControl componentName={`cashManagementAccount_${account.access}`}>
                        <IonItem
                            key={account._id}
                            button
                            routerLink={`/core/cashManagement/${account._id}`}
                            style={{
                                '--background': '#f9f9f9',
                                '--background-hover': '#f7f7f7',
                                '--background-activated': '#f7f7f7',
                                marginBottom: '12px',
                                borderRadius: '12px',
                                '--border-radius': '12px',
                                '--padding-start': '20px',
                                '--padding-end': '16px',
                                '--padding-top': '16px',
                                '--padding-bottom': '16px',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.08)',

                            }}
                            lines="none"
                        >
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                <div style={{
                                    backgroundColor: account.color || '#FF385C',
                                    borderRadius: '12px',
                                    padding: '12px',
                                    marginRight: '16px'
                                }}>
                                    <IonIcon
                                        icon={walletOutline}
                                        style={{
                                            color: 'white',
                                            fontSize: '24px'
                                        }}
                                    />
                                </div>

                                <IonLabel>
                                    <h2 style={{
                                        margin: '0 0 4px 0',
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        color: '#222222'
                                    }}>
                                        {account.name}
                                    </h2>
                                    <p style={{
                                        margin: '0',
                                        fontSize: '14px',
                                        color: '#717171',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px'
                                    }}>
                                        Access: {account.access}
                                        {account.default && (
                                            <IonChip
                                                style={{
                                                    margin: '0',
                                                    height: '20px',
                                                    fontSize: '12px',
                                                    '--background': '#e8f3ff',
                                                    '--color': '#2196f3',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Default
                                            </IonChip>
                                        )}
                                    </p>
                                </IonLabel>
                            </div>
                        </IonItem>
                    </AccessControl>
                ))}

                <IonItem
                    button
                    onClick={onAddAccount}
                    style={{
                        '--background': 'white',
                        '--background-hover': '#f7f7f7',
                        '--background-activated': '#f7f7f7',
                        marginBottom: '12px',
                        borderRadius: '12px',
                        '--border-radius': '12px',
                        '--padding-start': '20px',
                        '--padding-end': '16px',
                        '--padding-top': '16px',
                        '--padding-bottom': '16px',
                        // boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                        border: '1px dashed #e0e0e0'
                    }}
                    lines="none"
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        color: '#717171'
                    }}>
                        <IonIcon
                            icon={addOutline}
                            style={{
                                fontSize: '24px',
                                marginRight: '12px'
                            }}
                        />
                        Add Another Account
                    </div>
                </IonItem>
            </IonList>
        </div>
    );
};

export default AccountsList; 