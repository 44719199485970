import React, { useState } from 'react';
import {
    IonModal,
    IonContent,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonAvatar,
    IonAlert,
} from '@ionic/react';
import { closeOutline, pencilOutline, trashOutline } from 'ionicons/icons';
import { colors } from '../theme/colors';
import { fontSizes, fontWeights } from '../theme/typography';
import StandardContainer from '../commonComponents/StandardContainer';
import { format } from 'date-fns';
import Avatar from '../commonComponents/avatar';
import ScheduleEditSection from './ScheduleEditSection';
import axios from 'axios';

const ScheduleSummaryModal = ({
    isOpen,
    onClose,
    employee,
    date,
    schedule,
    breaks = [],
    onScheduleClick,
    onBreakClick,
    fetchScheduleData,
    businessId
}) => {
    const [editingSchedule, setEditingSchedule] = useState(false);
    const [editingBreak, setEditingBreak] = useState(null);

    const modalStyles = {
        '--border-radius': '16px',
        '--width': '100%',
        '--max-width': '600px'
    };

    const handleClose = () => {
        setEditingSchedule(false);
        setEditingBreak(null);
        onClose();
    };

    const handleScheduleSave = (data) => {
        onScheduleClick(employee, date, { schedule: { ...schedule, ...data }, breaks });
        setEditingSchedule(false);
    };

    const handleSaveBreak = async (data) => {
        const breakInput = {
          dateEpoch: date.getTime(),
          employeeId: employee._id,
          dateText: date,
          ...data,
        }

        console.log(breakInput, 'breakInput')
    
        try {
          await axios.post(`/employee/break`, breakInput)
          fetchScheduleData()
          handleClose()
        } catch (error) {
        //   setToast({ isOpen: true, message: 'Sorry, there was an issue saving. Try again later.', color: 'danger' })
        }
    }

    const handleBackFromBreak = () => {
        setEditingBreak(null);
    };

    if (editingSchedule) {
        return (
            <IonModal isOpen={isOpen} onDidDismiss={handleClose} style={modalStyles} backdropDismiss={false}>
                <IonContent>
                    <StandardContainer padding={20}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <h2 style={{ marginBottom: '4px' }}>
                                        {employee?.firstName}'s Schedule
                                    </h2>
                                    <div style={{
                                        fontSize: '15px',
                                        color: colors.black60,
                                        marginBottom: '8px'
                                    }}>
                                        {format(date, 'EEEE, MMMM d, yyyy')}
                                    </div>
                                </IonCol>
                                <IonCol size='auto'>
                                    <IonIcon
                                        onClick={handleClose}
                                        style={{
                                            fontSize: fontSizes.size24,
                                            color: colors.black40,
                                            fontWeight: fontWeights.weightBold,
                                            cursor: 'pointer'
                                        }}
                                        icon={closeOutline}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <ScheduleEditSection
                            employee={employee}
                            schedule={schedule}
                            type="schedule"
                            onSave={handleScheduleSave}
                            onCancel={handleClose}
                            fetchScheduleData={fetchScheduleData}
                        />
                    </StandardContainer>
                </IonContent>
            </IonModal>
        );
    }

    if (editingBreak) {
        return (
            <IonModal isOpen={isOpen} onDidDismiss={handleClose} style={modalStyles} backdropDismiss={false}>
                <IonContent>
                    <StandardContainer padding={20}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <h2 style={{ marginBottom: '4px' }}>
                                        {employee?.firstName}'s Break
                                    </h2>
                                    <div style={{
                                        fontSize: '15px',
                                        color: colors.black60,
                                        marginBottom: '8px'
                                    }}>
                                        {format(date, 'EEEE, MMMM d, yyyy')}
                                    </div>
                                </IonCol>
                                <IonCol size='auto' style={{ display: 'flex', gap: '12px' }}>
                                    {/* {editingBreak._id && (
                                        <IonIcon
                                            onClick={() => setShowDeleteAlert(true)}
                                            style={{
                                                fontSize: fontSizes.size24,
                                                color: '#ef4444',
                                                fontWeight: fontWeights.weightBold,
                                                cursor: 'pointer'
                                            }}
                                            icon={trashOutline}
                                        />
                                    )} */}
                                    <IonIcon
                                        onClick={handleBackFromBreak}
                                        style={{
                                            fontSize: fontSizes.size24,
                                            color: colors.black40,
                                            fontWeight: fontWeights.weightBold,
                                            cursor: 'pointer'
                                        }}
                                        icon={closeOutline}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <ScheduleEditSection
                            employee={employee}
                            type="break"
                            breakData={editingBreak}
                            onSave={handleSaveBreak}
                            onCancel={handleBackFromBreak}
                            fetchScheduleData={fetchScheduleData}
                        />
                    </StandardContainer>
                </IonContent>
            </IonModal>
        );
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose} style={modalStyles} backdropDismiss={false}>
            <IonContent>
                <StandardContainer padding={20}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                    {/* <div style={{
                                        width: '48px',
                                        height: '48px',
                                        borderRadius: '24px',
                                        overflow: 'hidden'
                                    }}> */}
                                    <IonAvatar>
                                        <Avatar employee={employee} businessId={businessId} />
                                    </IonAvatar>
                                    {/* </div> */}
                                    <div>
                                        <h2 style={{ margin: 0, marginBottom: '4px' }}>
                                            {employee?.firstName} {employee?.lastName}
                                        </h2>
                                        <div style={{
                                            fontSize: '15px',
                                            color: colors.black60
                                        }}>
                                            {format(date, 'EEEE, MMMM d, yyyy')}
                                        </div>
                                    </div>
                                </div>
                            </IonCol>
                            <IonCol size='auto'>
                                <IonIcon
                                    onClick={onClose}
                                    style={{
                                        fontSize: fontSizes.size24,
                                        color: colors.black40,
                                        fontWeight: fontWeights.weightBold,
                                        cursor: 'pointer'
                                    }}
                                    icon={closeOutline}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <div style={{
                        marginTop: '24px',
                        padding: '24px',
                        border: `1px solid ${colors.black10}`,
                        borderRadius: '16px',
                        backgroundColor: colors.white
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px'
                        }}>
                            {/* Working Hours Section */}
                            <div
                                onClick={() => setEditingSchedule(true)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                    cursor: 'pointer',
                                    padding: '12px',
                                    margin: '-12px',
                                    borderRadius: '12px',
                                    transition: 'all 0.2s ease'
                                }}
                                onMouseEnter={e => {
                                    e.currentTarget.style.backgroundColor = colors.black05;
                                }}
                                onMouseLeave={e => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                }}
                            >
                                <div style={{
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '12px',
                                    backgroundColor: '#F1F8FF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexShrink: 0
                                }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1976D2" strokeWidth="2">
                                        <circle cx="12" cy="12" r="10" />
                                        <polyline points="12 6 12 12 16 14" />
                                    </svg>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                    flex: 1
                                }}>
                                    <div style={{
                                        fontSize: '16px',
                                        fontWeight: fontWeights.weightBold,
                                        color: colors.black90
                                    }}>
                                        Working Hours
                                    </div>
                                    <div style={{
                                        fontSize: '15px',
                                        color: colors.black60
                                    }}>
                                        {schedule.enabled ?
                                            `${schedule.startTime} - ${schedule.endTime}` :
                                            'Day off'}
                                    </div>
                                </div>
                                <IonIcon
                                    icon={pencilOutline}
                                    style={{
                                        fontSize: '18px',
                                        color: colors.black40
                                    }}
                                />
                            </div>

                            {/* Breaks Section */}
                            {schedule.enabled && (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '12px',
                                    paddingTop: '16px',
                                    borderTop: `1px solid ${colors.black10}`
                                }}>
                                    {breaks.length === 0 ? (
                                        <div style={{
                                            fontSize: '15px',
                                            color: colors.black60,
                                            fontStyle: 'italic'
                                        }}>
                                            No breaks scheduled
                                        </div>
                                    ) : (
                                        breaks.map(breakItem => (
                                            <div
                                                key={breakItem._id}
                                                onClick={() => setEditingBreak(breakItem)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                    cursor: 'pointer',
                                                    padding: '12px',
                                                    margin: '-12px',
                                                    borderRadius: '12px',
                                                    transition: 'all 0.2s ease'
                                                }}
                                                onMouseEnter={e => {
                                                    e.currentTarget.style.backgroundColor = colors.black05;
                                                }}
                                                onMouseLeave={e => {
                                                    e.currentTarget.style.backgroundColor = 'transparent';
                                                }}
                                            >
                                                <div style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    borderRadius: '12px',
                                                    backgroundColor: '#F3E5F5',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexShrink: 0
                                                }}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#6A1B9A" strokeWidth="2">
                                                        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
                                                    </svg>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '4px',
                                                    flex: 1
                                                }}>
                                                    <div style={{
                                                        fontSize: '16px',
                                                        fontWeight: fontWeights.weightBold,
                                                        color: colors.black90
                                                    }}>
                                                        Break
                                                    </div>
                                                    <div style={{
                                                        fontSize: '15px',
                                                        color: colors.black60
                                                    }}>
                                                        {breakItem.startTime} - {breakItem.endTime}
                                                    </div>
                                                </div>
                                                <IonIcon
                                                    icon={pencilOutline}
                                                    style={{
                                                        fontSize: '18px',
                                                        color: colors.black40
                                                    }}
                                                />
                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {schedule.enabled && (
                        <div
                            onClick={() => setEditingBreak({})}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                cursor: 'pointer',
                                padding: '12px',
                                marginTop: '16px',
                                borderRadius: '8px',
                                transition: 'all 0.2s ease'
                            }}
                            onMouseEnter={e => {
                                e.currentTarget.style.backgroundColor = colors.black05;
                            }}
                            onMouseLeave={e => {
                                e.currentTarget.style.backgroundColor = 'transparent';
                            }}
                        >
                            <div style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '6px',
                                backgroundColor: '#F3E5F508',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexShrink: 0
                            }}>
                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#6A1B9A" strokeWidth="2" strokeOpacity="0.5">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </div>
                            <div style={{
                                fontSize: '14px',
                                color: colors.black40
                            }}>
                                Add a break
                            </div>
                        </div>
                    )}
                </StandardContainer>
            </IonContent>
        </IonModal>
    );
};

export default ScheduleSummaryModal; 