import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import { numberWithCommas } from '../utils'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'
import { AccessControl } from '../AccessControl'

export default function StatItems({ selectedChartItems, stats, month, year, subscriberAverage }) {
  const statsItems = [
    { label: 'Revenue', value: `$${numberWithCommas(stats?.totalSales || 0)}`, componentName: 'locationStats_revenue' },
    {
      label: 'Revenue from Subs (Beta)',
      value: `$${numberWithCommas(stats?.totalSalesFromSubscriptions || 0)}`,
      componentName: 'locationStats_revenue_from_subs',
    },
    { label: 'Appointments Completed', value: stats?.totalCompletedAppointments || 0 },
    { label: 'Value of Products', value: `$${numberWithCommas(stats?.productValue || 0)}` },
    { label: 'Total Tips', value: `$${numberWithCommas(stats?.totalTipValue || 0)}` },
    { label: 'Average Tip', value: `$${(stats?.averageTipPerAppointment || 0)?.toFixed(2)}` },
    { label: 'Taxes', value: `$${numberWithCommas(stats?.taxes || 0)}` },
    { label: 'Subscriptions Sold', value: stats?.countSubscriptionsSold },
    { label: 'Subscriptions Canceled', value: stats?.countOfSubscriptionsCanceled || 0 },
    {
      label: 'Avg Appt per Sub',
      value: `${Number.isFinite(subscriberAverage) ? subscriberAverage.toFixed(2) : '0.00'} (${
        Number.isFinite(subscriberAverage) && subscriberAverage !== 0 ? (new Date(year, month, 0).getDate() / subscriberAverage / 7).toFixed(1) : '0.0'
      } Weeks)`,
    },
  ]
  // Sort the statsItems based on the order of selectedChartItems
  const statsToDisplay = selectedChartItems
    ?.map((item) => statsItems.find((statItem) => statItem.label === item)) // Map selected items to their stats
    .filter((item) => item !== undefined) // Remove undefined items (if any)

  return stats ? (
    <IonList inset={true} mode='ios'>
      {statsToDisplay?.map((item, index) => {
        const uniqueKey = `${item.label}-${index}` // Ensure each key is unique

        return item.componentName ? (
          <AccessControl componentName={item.componentName} key={item.componentName}>
            <IonItem key={uniqueKey}>
              <IonLabel>
                <strong>{item.label}</strong>
              </IonLabel>
              <IonLabel slot='end'>{item.value}</IonLabel>
            </IonItem>
          </AccessControl>
        ) : (
          <IonItem key={uniqueKey}>
            <IonLabel>
              <strong>{item.label}</strong>
            </IonLabel>
            <IonLabel slot='end'>{item.value}</IonLabel>
          </IonItem>
        )
      })}
    </IonList>
  ) : (
    <StandardCenterCenter>
      <div style={{ margin: 50 }}>
        <IonSpinner name='dots' />
      </div>
    </StandardCenterCenter>
  )
}
