import { IonBadge, IonIcon } from "@ionic/react"
import { walkOutline } from "ionicons/icons"

const WalkInIcon = ({ appointments }) => {
  const walkInCount = appointments.filter(apt => apt.type === 'Walkin').length
  const walkinsNotComplete = appointments.filter(apt => apt.type === 'Walkin' && apt.status !== 'Complete' && apt.status !== 'Canceled').length


  if (walkInCount === 0) {
    return (
      <IonIcon
        icon={walkOutline}
        style={{ 
          fontSize: '24px',
          color: 'black'
        }}
      />
    )
  }


  if (walkinsNotComplete === 0) {
    return (
      <div style={{ position: 'relative' }}>
        <IonIcon
          icon={walkOutline}
          style={{ 
            fontSize: '24px',
            color: '#22c55e'  // Success green color
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '-4px',
            right: '-4px',
            width: '8px',
            height: '8px',
            backgroundColor: '#22c55e',
            borderRadius: '50%',
            border: '2px solid #ffffff'
          }}
        />
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <IonIcon
        icon={walkOutline}
        style={{ 
          fontSize: '24px',
          color: '#f57c00'  // Orange color for pending walk-ins
        }}
      />
      <IonBadge
        style={{
          position: 'absolute',
          top: '-8px',
          right: '-8px',
          backgroundColor: '#f57c00',
          borderRadius: '50%',
          padding: '4px 8px',
          fontSize: '12px',
          minWidth: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {walkinsNotComplete}
      </IonBadge>
    </div>
  )
}

  export default WalkInIcon