import axios from 'axios'
import { getAuth, getIdToken, signOut } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingScreen from './loadingScreen'
import RegisterNewUserToBusiness from './registerNewUser'
import GetLocationDataBasedOnLocationId from './Utils/GetLocationDataBasedOnLocationId'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState('loading')
  const [userData, setUserData] = useState('loading')
  const [businessList, setBusinessList] = useState('loading')
  const [businessData, setBusinessData] = useState('loading')
  const [businessDataError, setBusinessDataError] = useState(null)
  const [locationId, setLocationId] = useState(null)
  const [lastTokenRefresh, setLastTokenRefresh] = useState(0)
  const [bidRetries, setBidRetries] = useState(0)
  const [uidRetries, setUIDRetries] = useState(0)
  const [lidRetries, setLIDRetries] = useState(0)
  const [locationData, setLocationData] = useState(null)
  const [selectedLocationData, setSelectedLocationData] = useState(null)
  const history = useHistory()

  useEffect(() => {
    getAuth().onAuthStateChanged(setFirebase)
  }, [])
  
  useEffect(() => {
    getUserData()
  }, [firebaseUser])

  useEffect(() => {
    getBusinessData()
  }, [userData])

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        if (firebaseUser && firebaseUser !== 'loading') {
          const currentTime = Date.now();
          const thirtyMinutes = 30 * 60 * 1000;

          if (currentTime - lastTokenRefresh > thirtyMinutes) {
            const token = await refreshToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            config.headers['Authorization'] = `Bearer ${token}`;
            setLastTokenRefresh(currentTime);
          } else {
            config.headers['Authorization'] = `Bearer ${firebaseUser?.accessToken}`;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // const responseInterceptor = axios.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     console.log("hit1", error)
    //     return Promise.reject(error);
    //     if (error.response && error.response.status === 403) {
    //       try {
    //         // Check if the current token is still valid
    //         const currentToken = firebaseUser?.accessToken;
    //         console.log("hit1.1", currentToken)
    //         if (currentToken) {
    //           try {
    //             await getAuth().currentUser.getIdTokenResult(false);
    //             // If no error is thrown, the token is still valid
    //             error.config.headers['Authorization'] = `Bearer ${currentToken}`;
    //             console.log("hit2", error.config.headers['Authorization'])
    //             return axios(error.config);
    //           } catch (verifyError) {
    //             // Token is invalid, proceed with refresh
    //             console.log('Token invalid, refreshing...');
    //           }
    //         }

    //         // Refresh the token
    //         const newToken = await refreshToken();
    //         error.config.headers['Authorization'] = `Bearer ${newToken}`;
    //         axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
    //         return axios(error.config);
    //       } catch (refreshError) {
    //         // Handle refresh token failure, e.g., logout user
    //         console.log("hit4", refreshError);
    //         // logout();
    //         return Promise.reject(refreshError);
    //       }
    //     }
    //     console.log("hit3", error)``
    //     return Promise.reject(error);
    //   }
    // );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      // axios.interceptors.response.eject(responseInterceptor);
    };
  }, [firebaseUser, lastTokenRefresh]);

  // Include firebaseUser and lastTokenRefresh as dependencies

  async function refreshToken() {
    if (firebaseUser) {
      const token = await getIdToken(firebaseUser, true)
      return token
      // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  async function setFirebase(fb) {
    setFirebaseUser(fb)
    //if we dont have user logged in and if registerNewUser url is opened
    // if (window.location.pathname.includes('registerNewUser') && !fb) {
    //   history.push(window.location.pathname + window.location.search)
    //   return
    // }
    if (fb) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${fb.accessToken}`
      setUserData('loading')
      setBusinessData('loading')
      setLastTokenRefresh(Date.now())
    } else {
      setUserData(null)
      setBusinessData(null)
      setBusinessList(null)
      setLocationData(null)
      setLocationId(null)

      // history.push('/login')
    }
  }

  async function getUserData() {
    try {
      if (firebaseUser?.uid) {
        let response = await axios.get('/user')
        setUserData(response.data)
      } else {
        console.log("User Not Logged in")
      }
    } catch (error) {
      if (uidRetries == 0) {
        setUIDRetries(uidRetries + 1)
        setTimeout(getUserData, 10000)
      } else {
        setUserData(null)
      }
    }
  }

  function getFirstLocationWithPermissions(user, businessId) {
    console.log("User", user)
    if (user && user?.businesses && user?.businesses.length > 0) {
      for (const business of user.businesses) {
        if (business._id === businessId && business?.locations && business?.locations.length > 0) {
          const locationWithPermissions = business.locations.find(location => 
            location.entitlements && Object.values(location.entitlements).some(value => value === true)
          );
          if (locationWithPermissions) {
            return locationWithPermissions._id;
          }
        }
      }
    }
    return null;
  }

  async function getLocations(businessId) {
    try {
      if (businessId) {
        let response = await axios.get(`/location_v2/getList?businessId=${businessId}`);

        let selectedLocation = localStorage.getItem('defaultLocation');
        if (!selectedLocation) {
          selectedLocation = getFirstLocationWithPermissions(userData, businessId);
          localStorage.setItem('defaultLocation', selectedLocation);
        }
        const locationId = selectedLocation || (response.data && response.data[0]._id);

        axios.defaults.headers.common['prop-Locationid'] = locationId;
        setLocationId(locationId);
        setLocationData(response.data);
      }
    } catch (error) {
      if (lidRetries == 0) {
        setLIDRetries(1);
        setTimeout(() => getLocations(businessId), 10000);
      } else {
        setUserData(null);
      }
    }
  }

  async function getBusinessData() {
    // Don't run if user data is not available
    if (!userData || userData === 'loading') return;
  
    try {
      setBusinessDataError(null)
      // Set default business id in headers to null?????
      // axios.defaults.headers.common['prop-businessId'] = null;
      // Get all the lists of businesses
      let response = await axios.get(`/business_v2/list`);
  
      if (response.status === 200 && response.data?.length > 0) {
        const localStorageBusinessId = localStorage.getItem('defaultBusiness');
        let selectedBusinessObject = localStorageBusinessId 
          ? response.data.find((business) => business._id === localStorageBusinessId) 
          : null;
        selectedBusinessObject = selectedBusinessObject ?? response.data[0];
  
        localStorage.setItem('defaultBusiness', selectedBusinessObject._id);
        axios.defaults.headers.common['prop-businessId'] = selectedBusinessObject._id;

        setBusinessList(response.data);
        setBusinessData(selectedBusinessObject);
        getLocations(selectedBusinessObject._id);
        console.log("Retrieved Business", selectedBusinessObject)
  
      } else if (response.status === 200 && !response.data) {
        console.log('No business data returned.');
        setBusinessData(null)
      } else {
        throw new Error(`API responded with status ${response.status}`);
      }
  
    } catch (error) {
      console.error(error);
      setBusinessData(null);
      setBusinessDataError(error)
      // history.push('/businesserror');
    }
  }
  

  useEffect(() => {
    console.log(`Set Location Id: ${locationId}`)
    setSelectedLocationData(GetLocationDataBasedOnLocationId(locationData, locationId))
  }, [locationId])

  function logout() {
    signOut(getAuth())
    setFirebaseUser(null)
    setUserData(null)
    setLocationId(null)
    setLocationData(null)
    axios.defaults.headers.common['Authorization'] = null
    localStorage.removeItem('redirectTo')
    localStorage.removeItem('defaultBusiness')
    localStorage.removeItem('defaultLocation')
  }

  // if (!firebaseUser) {
  //
  //   return <Redirect noThrow to='/login' />
  // } else
  if (window.location.pathname.includes('registerNewUser') && (firebaseUser === 'loading' || userData === 'loading' || businessData === 'loading')) {
    // Redirect to RegisterNewUser component if the URL contains 'registerNewUser'
    return <RegisterNewUserToBusiness />
  } else if (firebaseUser === 'loading' || userData === 'loading' || businessData === 'loading') {
    return <LoadingScreen />
  } else {
    return (
      <AuthContext.Provider
        value={{
          firebaseUser,
          userData,
          getUserData,
          businessData,
          getBusinessData,
          locationId,
          setLocationId,
          logout,
          locationData,
          setLocationData,
          businessList,
          setBusinessList,
          setBusinessData,
          businessDataError,
          selectedLocationData
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}
