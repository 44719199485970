import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import { getFormattedMediumDate } from '../utils'
import CalendarView from './CalendarDayView'
import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonPage, IonTitle, IonToolbar, IonBadge } from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import { colors } from '../theme/colors'
import { fontWeights } from '../theme/typography'
import Avatar from '../commonComponents/avatar'
import ListViewAppointments from './ScrollingListView/index'
import '../Calendar/MyCalendar.css'
import { checkmark, list, walkOutline } from 'ionicons/icons'
import AppointmentsList from './AppointmentsListView'
import { format } from 'date-fns'
import WalkInAppointmentsModal from './AI_Calendar/WalkInAppointmentsModal'
import WalkInIcon from './AI_Calendar/WalkinIcon'
import TypesFilter from './components/TypesFilter'
import StylistsFilter from './components/StylistsFilter'

export default function Appointments() {
  const { businessData, userData, locationId } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [appointments, setAppointments] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = React.useState(false)
  const [loadingBreaks, setLoadingBreaks] = useState(false)
  const [loadingEmployees, setLoadingEmployees] = useState(false)
  const [loadingModifiedSchedules, setLoadingModifiedSchedules] = useState(false)
  const [loadingAppointments, setLoadingAppointments] = useState(false)
  const [breaks, setBreaks] = React.useState([])
  const [defaultView, setDefaultView] = useState(()=>{
    const storedView = localStorage.getItem('defaultView')
    if (storedView && (storedView === 'listView' || storedView === 'calendar')) {
      return storedView
    }
    return 'listView'
  })
  const [employees, setEmployees] = useState([])
  const [selectedStylists, setSelectedStylists] = useState([])
  const [selectedTypes, setSelectedTypes] = useState(['Scheduled', 'In Progress', 'Complete', 'No Show', 'Arrived'])
  const [employeeModifiedSchedules, setEmployeeModifiedSchedules] = useState([])
  const [filteredBarbers, setFilteredBarbers] = useState([])
  const [initalLoadForDayComplete, setInitalLoadForDayComplete] = useState(false)
  const [showWalkInModal, setShowWalkInModal] = useState(false)

  const handleSetSelectedDate = (date) => {
    console.log('handleSetSelectedDate', date)
    setSelectedDate(date)
    setInitalLoadForDayComplete(false)
  }

  const handlePrevWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() - 7)
    handleSetSelectedDate(newSelectedDate)
  }

  const handleNextWeek = (date) => {
    const newSelectedDate = new Date(date)
    newSelectedDate.setDate(newSelectedDate.getDate() + 7)
    handleSetSelectedDate(newSelectedDate)
  }

  const getEmployeesList = async () => {
    try {
      setLoadingEmployees(true)
      let response = await axios.get(`/employee/getPublicList?businessId=${businessData?._id}&locationId=${locationId}`)
      const filteredEmployeeFormattedData = response?.data?.map((item) => ({
        id: item._id,
        _id: item._id,
        title: item.firstName + ' ' + item.lastName,
        ...item,
      }))
      setEmployees(filteredEmployeeFormattedData)
    } catch (error) {
      console.error('Error fetching employee list:', error)
    } finally {
      setLoadingEmployees(false)
    }
  }

  const getEmployeeModifiedSchedules = async () => {
    if (defaultView !== 'calendar') return
    try {
      setLoadingModifiedSchedules(true)
      let response = await axios.get(`/employee/modifiedSchedulesByDate?dateText=${getFormattedMediumDate(selectedDate)}`)
      setEmployeeModifiedSchedules(response?.data || [])
    } catch (error) {
      console.error('Error fetching employee list:', error)
    } finally {
      setLoadingModifiedSchedules(false)
    }
  }

  const filterWorkingBarbers = () => {
    if (!employees.length) return

    const currentDay = format(selectedDate, 'EEEE')
    const dateString = getFormattedMediumDate(selectedDate)

    const workingBarbers = employees.filter(employee => {
      // Check modified schedule first
      const modifiedSchedule = employeeModifiedSchedules.find(
        schedule => schedule.employeeId === employee._id && schedule.dateText === dateString
      )
      if (modifiedSchedule) {
        employee.workingStartTime = modifiedSchedule.startTime
        employee.workingEndTime = modifiedSchedule.endTime
        employee.hasModifiedSchedule = true
        employee.modifiedSchedule = modifiedSchedule
        return modifiedSchedule.enabled
      }
      // Fall back to standard schedule

      const standardSchedule = employee.standardSchedule?.[currentDay]
      employee.workingStartTime = standardSchedule.startTime
      employee.workingEndTime = standardSchedule.endTime
      return standardSchedule?.enabled
    })

    setFilteredBarbers(workingBarbers)
  }

  useEffect(() => {
    filterWorkingBarbers()
  }, [employees, employeeModifiedSchedules, selectedDate])

  useEffect(() => {
    if (!loadingModifiedSchedules && !loadingBreaks && !loadingEmployees && !loadingAppointments) {
      setInitalLoadForDayComplete(true)
    }

  }, [loadingModifiedSchedules, loadingBreaks, loadingEmployees, loadingAppointments])

  // Update selectedStylists when filteredBarbers changes
  useEffect(() => {
    // if (filteredBarbers?.length > 0) {
    //   const allStylistNames = filteredBarbers.map(barber => barber.title)
    //   setSelectedStylists(allStylistNames)
    // }
  }, [filteredBarbers])

  const handleStylistChange = (stylistName) => {
    // setSelectedStylists(prev => {
    //   if (prev.includes(stylistName)) {
    //     return prev.filter(name => name !== stylistName)
    //   } else {
    //     return [...prev, stylistName]
    //   }
    // })
  }

  const handleTypeChange = (type) => {
    const updatedTypes = selectedTypes.includes(type) ? selectedTypes.filter((selectedType) => selectedType !== type) : [...selectedTypes, type]
    setSelectedTypes(updatedTypes)
  }

  // Filter appointments based on selected types
  const getFilteredAppointments = () => {
    if (!selectedTypes.length) return []
    return appointments.filter(appointment => selectedTypes.includes(appointment.status))
  }

  const refreshAppointments = () => {
    console.log("hit Refresh Appointments")
    getAppointmentsList()
    getEmployeesList()
    getEmployeeModifiedSchedules()
    getBreaks()
  }
  useEffect(() => {
    if (locationId && businessData?._id) {
      getAppointmentsList()
      getEmployeesList()
      getEmployeeModifiedSchedules()
      getBreaks()

      const interval = setInterval(() => {
        getAppointmentsList(true)
      }, 20000)
      return () => clearInterval(interval)
    }
  }, [selectedDate, businessData, locationId, defaultView])

  // // Retrieve the view from local storage on component mount

  // useEffect(() => {
  //   const storedView = localStorage.getItem('defaultView')
  //   if (storedView) {
  //     setDefaultView(storedView)
  //   }
  // }, [])
  // Function to set the view and save it to local storage
  const handleSetView = (view) => {
    setDefaultView(view)
    localStorage.setItem('defaultView', view)
  }
  const getAppointmentsList = async (hideLoader = false) => {
    if (defaultView == 'calendar') {
      if (!businessData?._id) return
      setLoading(!hideLoader)
      setLoadingAppointments(true)
      try {
        let response = await axios.get(`/appointment_v2/getList?dateText=${getFormattedMediumDate(selectedDate)}`)
        setAppointments(response.data)
      } catch (error) { }
      setLoading(false)
      setLoadingAppointments(false)
    }
  }

  const getBreaks = async () => {
    if (defaultView === 'calendar') {
      if (!businessData?._id) return
      try {
        setLoadingBreaks(true)
        let response = await axios.get(`/employee/searchForBreaksByDate?dateText=${getFormattedMediumDate(selectedDate)}`)
        setBreaks(response?.data || [])
      } catch (error) {
        console.error('Error fetching breaks:', error)
      } finally {
        setLoadingBreaks(false)
      }
    }
  }

  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Appointments</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }

  const handleView = () => {
    handleSetView(defaultView === 'listView' ? 'calendar' : 'listView')
  }


  return (
    <>
      <IonMenu contentId='appointment-menu' menuId='appointment-menu'>
        <IonContent className='regularBackground ion-padding '>
            <>
              <StylistsFilter 
                filteredBarbers={filteredBarbers}
                selectedStylists={selectedStylists}
                handleStylistChange={handleStylistChange}
                businessId={businessData?._id}
              />
              
              <TypesFilter selectedTypes={selectedTypes} handleTypeChange={handleTypeChange} />
            </>
        </IonContent>
      </IonMenu>
      <IonPage id='appointment-menu'>
        {defaultView === 'calendar' ?
          <IonHeader class="desktop-view" >
            <IonToolbar style={{backgroundColor: 'white', color: 'black', '--border-width': '0' }}>
              <IonTitle>Appointments</IonTitle>
              <IonButtons slot="end">
                <div
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    padding: '0 10px'
                  }}
                  onClick={() => setShowWalkInModal(true)}
                >
                  <WalkInIcon appointments={appointments} />
                </div>
                <IonButton onClick={() => handleSetView('listView')}>
                  <IonIcon color='primary' icon={list} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          : null}

        {defaultView == 'listView' ? (
          <>
            <AppointmentsList
              defaultView={defaultView}
              setDefaultView={handleSetView}
              handleView={handleView}
              refreshAppointments={refreshAppointments}
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              selectedStylists={selectedStylists}
              selectedTypes={selectedTypes}
              setAppointmentsInMenu={setAppointments}
              employees={employees}
              getBreaks={getBreaks}
              selectedDate={selectedDate}
              setSelectedDate={handleSetSelectedDate}
              isLoading={loading}
              loadingBreaks={loadingBreaks}
              loadingEmployees={loadingEmployees}
            />
          </>
        ) : defaultView === 'list' ? (
          <>
            {/* <NewListView /> */}

            <ListViewAppointments
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              selectedStylists={selectedStylists}
              selectedTypes={selectedTypes}
              setAppointmentsInMenu={setAppointments}
              employees={employees}
              getBreaks={getBreaks}
              selectedDate={selectedDate}
              setSelectedDate={handleSetSelectedDate}
              isLoading={loading}
              loadingBreaks={loadingBreaks}
              loadingEmployees={loadingEmployees}
            />
          </>
        ) : (
          <>
            <CalendarView
              defaultView={defaultView}
              setDefaultView={handleSetView}
              filteredBarbers={filteredBarbers}
              handleStylistChange={handleStylistChange}
              handleTypeChange={handleTypeChange}
              employees={employees}
              breaks={breaks}
              handleView={handleView}
              loading={loading}
              loadingBreaks={loadingBreaks}
              loadingEmployees={loadingEmployees}
              loadingModifiedSchedules={loadingModifiedSchedules}
              appointments={getFilteredAppointments()}
              selectedDate={selectedDate}
              locationId={locationId}
              setSelectedDate={handleSetSelectedDate}
              refreshAppointments={refreshAppointments}
              selectedStylists={selectedStylists}
              selectedTypes={selectedTypes}
              getBreaks={getBreaks}
              isLoading={!initalLoadForDayComplete}
              businessId={businessData?._id}
            />
          </>
        )}

        <WalkInAppointmentsModal
          isOpen={showWalkInModal}
          onClose={() => setShowWalkInModal(false)}
          appointments={appointments}
        />
      </IonPage>
    </>
  )
}
