import React from 'react';
import { IonList, IonItem, IonCheckbox, IonLabel } from '@ionic/react';
import { filterStyles } from './FilterStyles';

const TypesFilter = ({ selectedTypes, handleTypeChange }) => {
    return (
        <div style={filterStyles.container}>
            <h6 style={filterStyles.header}>
                Appointment Status
            </h6>
            <IonList mode='ios' lines='none' style={filterStyles.list}>
                {['Scheduled', 'Arrived', 'In Progress', 'Complete', 'Canceled', 'No Show'].map((item, index) => (
                    <IonItem key={index} style={filterStyles.item} onClick={() => handleTypeChange(item)}>
                        <IonLabel style={filterStyles.label}>{item}</IonLabel>
                        <IonCheckbox
                            slot='end'
                            style={filterStyles.checkbox}
                            checked={selectedTypes.includes(item)}
                            onIonChange={(e) => handleTypeChange(item)}
                        />
                    </IonItem>
                ))}
            </IonList>
        </div>
    );
};

export default TypesFilter; 